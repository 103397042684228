import {defineStore} from "pinia";
import {systemConfigurationService} from "@/core/api/system-configuration.api";
import {ToastMessage} from "@/core/services/general.service";

export const useSubModuleStore = defineStore('subModuleStore', {
    state: () => ({
        modules: [],
        editSubModuleDetails: null,
        editMode: false,
    }),
    getters: {
        getEditSubModuleData(state) {
            if (state.editMode && state.editSubModuleDetails) {
                return {
                    // ...state.editSubModuleDetails,
                    id: state.editSubModuleDetails.id,
                    module_id: state.editSubModuleDetails.module_id,
                    name: state.editSubModuleDetails.name,
                    route_name: state.editSubModuleDetails.route_name,
                    path: state.editSubModuleDetails.url,
                    description: state.editSubModuleDetails.description,
                };
            }
            return null;
        }
    },
    actions: {

        async getModules() {
            try {
                const response = await systemConfigurationService.getModules();
                this.modules = response.data.modules;
            } catch (e) {
                throw e;
            }
        },

        async createSubModule(formData) {
            try {
                return await systemConfigurationService.createSubModule(formData);
            } catch (e) {
                if (e.response && e.response.status === 422) {
                    const errors = e.response.data.errors;
                    for (const [field, messages] of Object.entries(errors)) {
                        messages.forEach(message => {
                            ToastMessage('error', `${field}: ${message}`, 'Hata!');
                        });
                    }
                } else {
                    throw e;
                }
            }
        },
        async editSubModule(formData) {
            try {
                return await systemConfigurationService.editSubModule(formData);
            } catch (e) {
                if (e.response && e.response.status === 422) {
                    const errors = e.response.data.errors;
                    for (const [field, messages] of Object.entries(errors)) {
                        messages.forEach(message => {
                            ToastMessage('error', `${field}: ${message}`, 'Hata!');
                        });
                    }
                } else {
                    throw e;
                }
            }
        },
        setEditMode(editMode, moduleDetails = null) {
            this.editMode = editMode;
            this.editSubModuleDetails = moduleDetails;
        }
    }
});