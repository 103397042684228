<template>
  {{ price }}
</template>

<script setup>
import {useAuthStore} from "@/stores/authStore";
import {computed} from "vue";
import {number_format, priceFormatter} from "@/core/services/general.service";


const props = defineProps({
  price: {
    type: Number,
    required: true
  }
})

const authStore = useAuthStore();

const price = computed(() => priceFormatter(props.price));


</script>

<style scoped>

</style>