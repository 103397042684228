<template>
  <div class="container-fluid">
    <div class="row mb-4 d-flex d-flex justify-content-between">
      <div class="col-md-4">
        <label for="">Modül Grubu Seçin:</label>
        <select v-model="selectedModuleGroup" @change="getModules" name="" id="" class="form-control">
          <option v-for="mg in moduleGroups" :key="mg.id" :value="mg.id">{{ mg.name }}</option>
        </select>
      </div>

      <div class="col-md-4 d-flex justify-content-end">
        <button @click="saveSorting()" class="btn btn-primary">
          Kaydet <i style="margin-left: 10px;" class="ml-2 fa fa-save"></i>
        </button>
      </div>
    </div>

    <DxDataGrid
        :data-source="modules"
        key-expr="id"
        :show-borders="true"
        :height="440"
        width="100%"
        id="dxGirdSortModule"
    >
      <DxRowDragging
          :allow-reordering="true"
          :on-reorder="onReorder"
          :show-drag-icons="true"
      />
      <DxSorting mode="none"/>
      <DxScrolling mode="virtual"/>

      <DxColumn width="200" data-field="ico" caption="Icon" cell-template="iconPreviewTemplate"></DxColumn>

      <template #iconPreviewTemplate="{ data: rowInfo }">
        <div class="flex justify-center">
          <i class="sidebar-module-icon" :class="rowInfo.data.ico"></i> {{ rowInfo.data.ico }}
        </div>
      </template>

      <DxColumn
          :width="150"
          data-field="name"
          caption="Modül"
      />


    </DxDataGrid>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import {
  DxDataGrid, DxColumn, DxScrolling, DxRowDragging, DxSorting, DxItem, DxToolbar,
} from 'devextreme-vue/data-grid';
import {useModuleSortingStore} from '@/stores/SystemConfiguration/moduleSortingStore';
import {ToastMessage} from "@/core/services/general.service";
import {BlockContainer, UnBlocker} from "@/core/services/general.service";

const moduleGroups = ref([]);
const modules = ref([]);

const moduleSortingStore = useModuleSortingStore();

onMounted(async () => {
  await getModuleGroups();
});

const selectedModuleGroup = ref('');

const getModuleGroups = async () => {
  await moduleSortingStore.getModuleGroups();
  moduleGroups.value = moduleSortingStore.moduleGroups;
  selectedModuleGroup.value = moduleGroups.value[0].id;
  await getModules();
}

const getModules = async () => {
  await moduleSortingStore.getModules(selectedModuleGroup.value);
  modules.value = [...moduleSortingStore.modules];
}

const onReorder = async (e) => {
  const visibleRows = e.component.getVisibleRows();
  const toIndex = modules.value.findIndex((item) => item.id === visibleRows[e.toIndex].data.id);
  const fromIndex = modules.value.findIndex((item) => item.id === e.itemData.id);
  const newModules = [...modules.value];

  newModules.splice(fromIndex, 1);
  newModules.splice(toIndex, 0, e.itemData);
  modules.value = newModules;
  // console.log("Current module groups order:", modules.value);


};


const saveSorting = async () => {
  let loader = BlockContainer('dxGirdSortModule');
  try {
    let response = await moduleSortingStore.saveOrderModules(modules.value)
    if (response?.status === 200) {
      ToastMessage('success', response.data.message, 'Successfully!');
    }
  } catch (e) {
    throw e;
  } finally {
    UnBlocker(loader);
  }
}
</script>
