import {defineStore} from "pinia";
import {systemConfigurationService} from "@/core/api/system-configuration.api";
import {ToastMessage} from "@/core/services/general.service";

export const useRoleStore = defineStore('roleStore', {
    state: () => ({
        roleGroups: [],
    }),
    getters: {},
    actions: {

        async getRoleGroups() {
            try {
                const response = await systemConfigurationService.getRoleGroups();
                this.roleGroups = response.data.role_groups;
            } catch (e) {
                throw e;
            }
        },
    }
});