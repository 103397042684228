<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <table
            class="table table-bordered table-striped mb-4 table-padding-5-20-5-20"
        >
          <thead>
          <tr>
            <th colspan="2" class="text-center fw-bolder">
              {{ $translate('users.management.details_modal.tabs.user.general_title') }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="info">
              {{ $translate('users.management.details_modal.tabs.user.id') }}
            </td>
            <td>{{ the_user.id }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('users.management.details_modal.tabs.user.name_surname') }}
            </td>
            <td>{{ the_user.name }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('users.management.details_modal.tabs.user.email') }}
            </td>
            <td>{{ the_user.email }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('users.management.details_modal.tabs.user.phone') }}
            </td>
            <td>{{ the_user.full_phone }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('users.management.details_modal.tabs.user.role') }}
            </td>
            <td>
              <RoleBadge :role="the_user.role?.name"/>
            </td>
          </tr>
          <tr>
            <td>
              {{ $translate('users.management.details_modal.tabs.user.agency') }}
            </td>
            <td>{{ the_user.agency?.title }}
              <span @click="emit('agency-details', the_user.agency_id)" v-if="the_user.agency" style="cursor: pointer;"
                    class="ms-2">
                <i style="color: dodgerblue;" class="fa fa-globe"></i>
              </span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-6">
        <table
            class="table table-bordered table-striped mb-4 table-padding-5-20-5-20"
        >
          <thead>
          <tr>
            <th colspan="2" class="text-center fw-bolder">
              {{ $translate('users.management.details_modal.tabs.user.general2_title') }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="info">
              {{ $translate('users.management.details_modal.tabs.user.status') }}
            </td>
            <td>
              <StatusBadge :status="the_user.status"/>
            </td>
          </tr>
          <tr>
            <td>
              {{ $translate('users.management.details_modal.tabs.user.status_description') }}
            </td>
            <td>{{ the_user.status_description }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('common.created_at') }}
            </td>
            <td>{{ the_user.created_at }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('common.created_by') }}
            </td>
            <td>{{ the_user.user?.name_surname }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('users.management.details_modal.tabs.user.last_login_at') }}
            </td>
            <td>{{ the_user.last_login_at }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="col-md-6">
        <DeleteInfo :model="the_user" :delete-action="handleUserRollback"
                    :rollback-confirm-message="userDeleteRollbackConfirmMessage"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed} from 'vue';
import {$translate} from '@/core/services/translator.service';
import {useUserStore} from '@/stores/userStore';
import RoleBadge from '@/components/users/mangement/RoleBadge.vue';
import StatusBadge from '@/components/StatusBadge.vue';
import DeleteInfo from "@/components/DetailModals/DeleteInfo.vue";
import {errorService} from "@/core/services/error.service";
import {SwalClose, ToastMessage} from "@/core/services/general.service";
import {SwalLoading} from "@/core/services/general.service";

const emit = defineEmits(['agency-details', 'user-details-refresh']);

const userDeleteRollbackConfirmMessage = $translate('users.management.messages.frontend.delete_rollback_confirm_text');


const userStore = useUserStore();
const the_user = computed(() => userStore.selected_user);

const handleUserRollback = async (id) => {

  try {
    SwalLoading('info', 'We undo the deletion.', 'Please Wait!')
    const response = await userStore.rollbackUser(id);

    ToastMessage('success', response.data.message, 'Successfully!');
    emit('user-details-refresh', id)

  } catch (e) {
    errorService.apiClientError(e);
  } finally {
    SwalClose();
  }
}
</script>


<style scoped></style>
