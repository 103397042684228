<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <ul class="nav nav-tabs" id="icon-tab" role="tablist">
        <li class="nav-item">
          <a
              class="nav-link active"
              id="profile-icon-tab"
              data-bs-toggle="tab"
              href="#user-details-users"
              role="tab"
              aria-controls="user-details-users"
              aria-selected="false"
          >
            <i class="fa fa-user"></i>
            Users ({{ filteredData.length }})
          </a>
        </li>
      </ul>

      <div class="input-search d-flex align-content-center justify-content-between">

        <button @click="modalAddUserRef.getUsersToModal()"
                class="me-2 btn btn-primary btn-xs d-flex align-items-center">
          <i class="fa fa-plus-circle"></i>
          <span style="white-space: nowrap;" class="ms-2">Add</span>
        </button>

        <input type="text"
               v-model="searchQuery"
               class=" form-control form-control-sm" placeholder="search...">
      </div>

    </div>

    <div class="tab-content p-2" id="icon-tabContent">
      <div
          class="tab-pane fade show active"
          id="user-details-users"
          role="tabpanel"
          aria-labelledby="icon-home-tab"
      >
        <div class="row">
          <div class="col-md-12 table-container">
            <table
                class="table table-bordered table-striped mb-4 table-padding-5-20-5-20"
            >
              <thead>
              <tr>
                <th>ID</th>
                <th>Name Surname</th>
                <th>Role</th>
                <th>Agency</th>
                <th>Detail</th>
                <th>Delete</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="filteredData.length" v-for="details in filteredData" :key="details.id">
                <td>{{ details.user.id }}</td>
                <td>{{ details.user.name }}</td>
                <td>
                  <RoleBadge :role="details.user.role.name"/>
                </td>
                <td>{{ details.user.agency?.title }}</td>
                <td>
                  <button @click="emit('user-details', details.user.id)" class="btn btn-xs btn-outline-primary"
                  >Detail
                  </button>
                </td>
                <td>
                  <button @click="emit('delete-permission', details.user.id)" class="btn btn-xs btn-outline-danger">
                    Delete
                  </button>
                </td>
              </tr>
              <tr v-else>
                <td colspan="12" class="text-center text-danger">There is no data here.</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ModalAddUser ref="modalAddUserRef" @refresh-permission-details="emit('refresh-permission-details')"/>
</template>

<script setup>
import RoleBadge from "@/components/users/mangement/RoleBadge.vue";
import {useDefaultRolePermissionStore} from "@/stores/SystemConfiguration/defaultRolePermissionStore";
import {computed, ref} from "vue";
import ModalAddUser from "@/components/configuration/DefaultRolePermission/PermissionDetail/includes/ModalAddUser.vue";

const drpStore = useDefaultRolePermissionStore();
const the_permission = computed(() => drpStore.permission_details);
const searchQuery = ref('');
const modalAddUserRef = ref(null);

const filteredData = computed(() => {
  if (!searchQuery.value) {
    return the_permission.value?.users || [];
  }
  const lowerCaseQuery = searchQuery.value.toLowerCase().trimLeft().trimRight();
  return the_permission.value?.users.filter(details =>
      details.user.id.toString().toLowerCase().includes(lowerCaseQuery) ||
      details.user.name.toLowerCase().includes(lowerCaseQuery) ||
      details.user.role.name.toLowerCase().includes(lowerCaseQuery) ||
      details.user.role.display_name.toLowerCase().includes(lowerCaseQuery) ||
      details.user.agency?.title.toLowerCase().includes(lowerCaseQuery)
  ) || [];
});

const emit = defineEmits(['user-details', 'delete-permission', 'refresh-permission-detail']);

</script>

<style lang="scss" scoped>
.input-search {
  max-width: 50%;
}

.table-container {
  white-space: nowrap;
  overflow-x: auto;
  max-height: 300px;
}
</style>