<template>
  <div id="top-tabContent" class="container-fluid">
    <div class="row mb-4">
      <div class="col-md-4">
        <label for="">Modül Grubu Seçin:</label>
        <select v-model="selectedModuleGroup" @change="getModules" name="" id="" class="form-control">
          <option v-for="mg in moduleSortingStore.allModuleData" :key="mg.id" :value="mg.id">{{ mg.name }}</option>
        </select>
      </div>
      <div class="col-md-4">
        <label for="">Modül Seçin:</label>
        <select v-model="selectedModule" @change="getSubModules" name="" id="" class="form-control">
          <option v-for="module in modules" :key="module.id" :value="module.id">{{ module.name }}</option>
        </select>
      </div>

      <div class="col-md-4 d-flex justify-content-end">
        <button @click="saveSorting()" class="btn btn-primary">
          Kaydet <i style="margin-left: 10px;" class="ml-2 fa fa-save"></i>
        </button>
      </div>

    </div>

    <DxDataGrid
        :data-source="subModules"
        key-expr="id"
        :show-borders="true"
        :height="440"
        width="100%"
        id="dxGridSortSubModule"
    >
      <DxRowDragging
          :allow-reordering="true"
          :on-reorder="onReorder"
          :show-drag-icons="true"
      />
      <DxSorting mode="none"/>
      <DxScrolling mode="virtual"/>


      <DxColumn
          :width="150"
          data-field="name"
          caption="Alt Modül Adı"
      />
    </DxDataGrid>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import {
  DxDataGrid, DxColumn, DxScrolling, DxRowDragging, DxSorting,
} from 'devextreme-vue/data-grid';
import {useModuleSortingStore} from '@/stores/SystemConfiguration/moduleSortingStore';
import {BlockContainer, ToastMessage, UnBlocker} from "@/core/services/general.service";

const allModuleData = ref([]);
const modules = ref([]);
const subModules = ref([]);

const moduleSortingStore = useModuleSortingStore();

onMounted(async () => {
  await getSubModulesAndOthers();
});

const selectedModuleGroup = ref('');
const selectedModule = ref('');

const getSubModulesAndOthers = async () => {
  await moduleSortingStore.getSubModulesAndOthers();
  allModuleData.value = moduleSortingStore.allModuleData;
  if (allModuleData.value.length > 0) {
    selectedModuleGroup.value = allModuleData.value[0].id;
    await getModules();
    if (modules.value.length > 0) {
      selectedModule.value = modules.value[0].id;
      await getSubModules();
    }
  }
}

const getModules = async () => {
  let modulesData = [];
  allModuleData.value.forEach(val => {
    if (val.id === selectedModuleGroup.value) {
      val.modules.forEach(module => {
        modulesData.push(module);
      });
    }
  });

  modules.value = modulesData;
  if (modules.value.length > 0) {
    selectedModule.value = modules.value[0].id;
    await getSubModules();
  } else {
    selectedModule.value = '';
    subModules.value = [];
  }
};

const getSubModules = async () => {
  let subModulesData = [];
  modules.value.forEach(module => {
    if (module.id === selectedModule.value) {
      module.sub_modules.forEach(subModule => {
        subModulesData.push(subModule);
      });
    }
  });

  subModules.value = subModulesData;
};

const onReorder = async (e) => {
  const visibleRows = e.component.getVisibleRows();
  const toIndex = subModules.value.findIndex((item) => item.id === visibleRows[e.toIndex].data.id);
  const fromIndex = subModules.value.findIndex((item) => item.id === e.itemData.id);
  const newSubModules = [...subModules.value];

  newSubModules.splice(fromIndex, 1);
  newSubModules.splice(toIndex, 0, e.itemData);
  subModules.value = newSubModules;

};


const saveSorting = async () => {
  let loader = BlockContainer('dxGridSortSubModule');
  try {
    let response = await moduleSortingStore.saveOrderSubModules(subModules.value);
    if (response?.status === 200) {
      ToastMessage('success', response.data.message, 'Successfully!');
    }
  } catch (e) {
    throw e;
  } finally {
    UnBlocker(loader);
  }
}

</script>
