<template>
  <li class="language-nav" v-click-outside="handleClickOutside">
    <div class="translate_wrapper">
      <div class="translate_wrapper" :class="{ active: isActive }">
        <div @click="display()" class="current_lang">
          <div class="lang">
            <i class="flag-icon" :class="langIcon"></i>
            <span class="lang-txt">{{ langLangauge }}</span>
          </div>
        </div>

        <div class="more_lang" :class="{ active: isActive }">
          <div
            @click="changeLocale(l)"
            class="lang"
            v-for="(l, index) in languages"
            :key="index"
          >
            <i class="flag-icon" :class="l.icon"></i>
            <span class="lang-txt">
              {{ l.name }}
              <span>{{ l.short }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { useAuthStore } from '@/stores/authStore';
  import clickOutside from './click-outside.js'; // Direktifi içe aktar
  import { ToastMessage } from '@/core/services/general.service';
  import { $translate } from '@/core/services/translator.service.js';

  export default {
    name: 'languagePage',
    directives: {
      clickOutside, // Direktifi componentte kullanıma aç
    },
    data() {
      return {
        isActive: false,
      };
    },
    computed: {
      ...mapGetters({
        langIcon: 'langIcon',
        langLangauge: 'langLangauge',
      }),
      languages() {
        const authStore = useAuthStore();
        return authStore.languages;
      },
    },
    methods: {
      display() {
        this.isActive = !this.isActive;
      },
      changeLocale(locale) {
        // console.log(locale);
        this.$i18n.locale = locale.id;
        this.$store.dispatch('setLang', locale);
        ToastMessage(
          'success',
          $translate('system.messages.language_applied'),
          $translate('system.messages.language_applied_title')
        );
        this.display();
      },
      handleClickOutside() {
        this.isActive = false;
      },
      handleKeyDown(event) {
        if (event.key === 'Escape') {
          this.isActive = false;
        }
      },
    },
    mounted() {
      this.$store.dispatch('setLang', {
        id: localStorage.getItem('currentLanguage') || 'EN',
        icon: localStorage.getItem('currentLanguageIcon') || 'flag-icon-us',
      });
      document.addEventListener('keydown', this.handleKeyDown);
    },
    beforeUnmount() {
      document.removeEventListener('keydown', this.handleKeyDown);
    },
  };
</script>

<style scoped></style>
