/**
 * UpperCase First Character
 * @param string string
 * @return string
 * */

export const ucfirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * UpperCase First Character of Each Word
 * @param string string
 * @returns string
 */
export const ucwords = (string) => {
    return string.replace(/\b\w/g, (char) => char.toUpperCase());
}
