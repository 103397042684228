import axios from "axios";
import router from "@/router";
import {ToastMessage} from "@/core/services/general.service";
import {useAuthStore} from "@/stores/authStore";


export const apiClientFullURL = process.env.VUE_APP_API_URL + "/api";

export const apiClient = axios.create({
    baseURL: apiClientFullURL,
    headers: {
        'Content-type': 'application/json; charset=utf-8',
        'Accept': 'application/json',
    },
    timeout: 5000000,
});


apiClient.interceptors.request.use((config) => {

    config.headers['Currency'] = localStorage.getItem('currency') ?? null;
    config.headers['Accept-Language'] = localStorage.getItem('currentLanguage') ?? null;

    return config;
}, (error) => {
    return Promise.reject(error);
});

apiClient.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        const authStore = useAuthStore();

        if (error.response && error.response.status === 401) {
            const responseData = error.response.data;

            if (responseData.error === "token_has_expired") {
                ToastMessage('error', 'Token has expired! You have to login again.', 'Error');
                authStore.logout();
            } else if (responseData.message === "Unauthenticated.") {
                ToastMessage('error', 'Please login first!', 'Error');
                authStore.logout();
            }
        }

        return Promise.reject(error);
    }
);