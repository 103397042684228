<template>
  <li class="language-nav" v-click-outside="handleClickOutside">
    <div class="translate_wrapper">
      <div class="translate_wrapper" :class="{ active: isActive }">
        <div @click="display()" class="current_lang">
          <div class="lang">
            <i class="text-primary" :class="authStore.currentCurrency?.icon"></i>
            <span class="lang-txt text-primary">{{ authStore.currentCurrency?.code }}</span>
          </div>
        </div>

        <div class="more_lang" :class="{ active: isActive }">
          <div
              @click="changeCurrency(currency)"
              class="lang"
              v-for="(currency, index) in currencies"
              :key="index"
          >
            <i :class="currency.icon"></i>
            <span class="lang-txt">
              {{ currency.code }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script setup>
import {useAuthStore} from "@/stores/authStore";
import {computed, onBeforeMount, onMounted, ref} from "vue";
import {changeSystemCurrency} from "@/core/services/general.service";

const authStore = useAuthStore();
const currencies = computed(() => authStore.currencies)

const changeCurrency = (currency) => {

  changeSystemCurrency(currency.code);

  currency.default = true;

  isActive.value = false;
}

const isActive = ref(false)

const handleClickOutside = () => {
  isActive.value = false;
}

const display = () => {
  isActive.value = !isActive.value;
}


const handleKeyDown = (event) => {
  if (event.key === 'Escape') {
    isActive.value = false;
  }
}

onMounted(() => {
  document.addEventListener('keydown', handleKeyDown);

})

onBeforeMount(() => {
  document.removeEventListener('keydown', handleKeyDown);
})
</script>

<style scoped></style>
