<template>
  <span class="badge" :class="roleClass">{{ $translate('system.roles.' + role) }}</span>
</template>

<script setup>
import {computed} from "vue";
import {$translate} from "../../../core/services/translator.service";

const props = defineProps({
  role: {
    type: String,
    required: true
  }
});

const roleColors = {
  super_admin: 'badge-primary',
  agency_admin: 'badge-info',
  reservationist: 'badge-info',
  accounting: 'badge-warning',
  agency_accounting: 'badge-dark',
  agency_operator: 'badge-info',
  dev_ops: 'badge-danger'
};

const roleClass = computed(() => roleColors[props.role]);
</script>

<style scoped>
span {
  //font-size: .75rem;
}
</style>
