import {defineStore} from "pinia";
import {systemConfigurationService} from "@/core/api/system-configuration.api";
import {ToastMessage} from "@/core/services/general.service";

export const usePermissionStore = defineStore('permissionStore', {
    state: () => ({
        sub_modules: [],
        editPermissionDetails: null,
        editMode: false,
    }),
    getters: {
        getEditSubModuleData(state) {
            if (state.editMode && state.editPermissionDetails) {
                return {
                    // ...state.editPermissionDetails,
                    id: state.editPermissionDetails.id,
                    sub_module_id: state.editPermissionDetails.sub_module_id,
                    name: state.editPermissionDetails.name,
                    guard_name: state.editPermissionDetails.guard_name,
                    description: state.editPermissionDetails.description,
                    assignable_by_agent: state.editPermissionDetails.assignable_by_agent ? "true" : "false",
                };
            }
            return null;
        }
    },
    actions: {

        async getSubModules() {
            try {
                const response = await systemConfigurationService.getSubModules();
                this.sub_modules = response.data.sub_modules;
            } catch (e) {
                throw e;
            }
        },

        async createPermission(formData) {
            try {
                return await systemConfigurationService.createPermission(formData);
            } catch (e) {
                if (e.response && e.response.status === 422) {
                    const errors = e.response.data.errors;
                    for (const [field, messages] of Object.entries(errors)) {
                        messages.forEach(message => {
                            ToastMessage('error', `${field}: ${message}`, 'Hata!');
                        });
                    }
                } else {
                    throw e;
                }
            }
        },
        async editPermission(formData) {
            try {
                return await systemConfigurationService.editPermission(formData);
            } catch (e) {
                if (e.response) {
                    if (e.response.status === 422) {
                        const errors = e.response.data.errors;
                        for (const [field, messages] of Object.entries(errors)) {
                            messages.forEach(message => {
                                ToastMessage('error', `${field}: ${message}`, 'Hata!');
                            });
                        }
                    } else if (e.response.status === 500) {
                        ToastMessage('error', e.response.data.message, 'Error!');
                    }
                } else {
                    throw e;
                }
            }
        },
        setEditMode(editMode, moduleDetails = null) {
            this.editMode = editMode;
            this.editPermissionDetails = moduleDetails;
        }
    }
});