<template>
  <div id="ModalEditAgencySelfMarkup" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Agency Self Markup</h4>
          <button
              class="btn-close"
              type="button"
              aria-label="Close"
              @click="closeModal"
          ></button>
        </div>
        <div id="ModalBodyEditAgencySelfMarkup" class="modal-body">

          <div class="row">
            <div class="col-md-12 mb-4">
              <DxSelectBox
                  label="Package Type:"
                  labelMode="outside"
                  :items="agencyStore.active_package_types"
                  displayExpr="with_package_name"
                  valueExpr="id"
                  :read-only="editMode"
                  v-model="formData.package_type_id"
              >

                <template #item="{ data }">
                  <b :class="checkForAddability(data.id) ? 'text-danger':'text-primary'"
                  >{{ ucwords(data.with_package_name) }}</b>
                </template>
              </DxSelectBox>
            </div>

            <div class="col-md-12 mb-4">
              <DxTextBox
                  label="Markup Category:"
                  labelMode="outside"
                  read-only="true"
                  v-model="formData.markup_category"
                  :input-attr="{ 'aria-label': 'Name' }"
              />
            </div>

            <div v-if="formData.markup_category === 'per-transaction' && savePermission.status" class="col-md-12 mb-4">
              <DxSelectBox
                  label="Currency:"
                  labelMode="outside"
                  v-model="formData.markup_currency"
                  :items="authStore.currencies"
                  displayExpr="code"
                  valueExpr="code"
                  :read-only="false"
              >
              </DxSelectBox>
            </div>

            <div v-if="formData.markup_category === 'per-transaction' && savePermission.status" class="col-md-12 mb-4">
              <CurrencyPriceNumberBox
                  v-model:boxValue="formData.markup_amount"
                  label="Markup:" label-mode="outside"
                  :currency="formData.markup_currency"
              />
            </div>

            <div v-if="formData.markup_category === 'percent' && savePermission.status" class="col-md-12 mb-3">
              <DxNumberBox
                  v-model="formData.markup_percentage"
                  :min="0"
                  :max="100"
                  :show-spin-buttons="true"
                  label="Markup (%):" label-mode="outside"
              />
            </div>

            <div v-if="!savePermission.status" class="col-md-12">
              <p class="fw-bolder" :class="savePermission.message_class">
                {{ savePermission.message }}
              </p>
            </div>
          </div>


        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">
            {{ $translate('common.close') }}
          </button>
          <button type="button" :disabled="!savePermission.status" class="btn btn-primary" @click="handleSave">
            {{ $translate('common.save_changes') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref, watchEffect} from 'vue';
import {Modal} from 'bootstrap';
import {$translate} from "@/core/services/translator.service";
import DxSelectBox from 'devextreme-vue/select-box';
import {useAgencyStore} from "@/stores/agencyStore";
import {ucfirst, ucwords} from "@/core/services/string.service";
import DxTextBox from 'devextreme-vue/text-box';
import CurrencyPriceNumberBox from "@/components/DevExtreme/DxField/CurrencyPriceNumberBox.vue";
import {useAuthStore} from "@/stores/authStore";
import {DxNumberBox} from 'devextreme-vue/number-box';
import {errorService} from "@/core/services/error.service";
import {BlockContainer, ToastMessage, UnBlocker} from "@/core/services/general.service";
import loaders from "vue-loading-overlay/src/loaders";

const agencyStore = useAgencyStore();
const authStore = useAuthStore();

const modalUserDetails = ref()

const savePermission = ref({
  status: false,
  message: 'Please fill in the fields.',
  message_class: 'text-info',
});


const editMode = ref(false);

const checkForAddability = (package_type_id) => {
  const agencySelfMarkups = agencyStore?.selected_agency?.current_markups?.just_self_markups;

  if (agencySelfMarkups) {
    return agencySelfMarkups.some(markup => markup.package_type_id === package_type_id);
  }

  return false;
}


const formData = ref({
  markup_id: null,
  package_type_id: null,
  markup_category: null,
  markup_currency: authStore.currentCurrency?.code,
  markup_amount: 0,
  markup_percentage: 0,
});

watchEffect(() => {
  const result = agencyStore.active_package_types.find(type => type.id === formData.value.package_type_id);

  formData.value.markup_category = result?.markup_category?.name || null;

  if (!formData.value.markup_category) {
    savePermission.value.status = false;
    return;
  }

  // check if the package type is already defined or undefined.
  const agencySelfMarkups = agencyStore.getAgencySelfMarkups;

  if (agencySelfMarkups) {
    const existingMarkup = agencySelfMarkups.some(markup => markup.package_type_id === formData.value.package_type_id);

    if (existingMarkup && !editMode) {
      savePermission.value.status = false;
      savePermission.value.message = "Package type that you choose is already defined to the agency! Please select a package type that is not defined.";
      savePermission.value.message_class = 'text-danger';
    } else {
      savePermission.value.status = true;
    }
  } else {
    savePermission.value.status = false;
  }
});


const emit = defineEmits([
  'refresh-agency-detail'
])

const handleSave = async () => {

  if (!savePermission.value.status)
    return false;

  const loader = BlockContainer('ModalBodyEditAgencySelfMarkup');

  try {

    formData.value.agency_id = agencyStore.selected_agency.id;

    const response = (editMode.value)
        ? await agencyStore.updateAgencyMarkup(formData.value)
        : await agencyStore.createAgencyMarkup(formData.value);

    ToastMessage('success', response.data.message, 'Successfully!');

    closeModal();

    emit('refresh-agency-detail');

  } catch (e) {
    errorService.apiClientError(e, true, true, 'bag');
  } finally {
    UnBlocker(loader);
  }
}

onMounted(() => {
  modalUserDetails.value = new Modal(document.getElementById('ModalEditAgencySelfMarkup'), {
    backdrop: false,
    keyboard: false,
    focus: true,
  });
});

const openModal = (editModeParam = false, editDetails = {}) => {
  modalUserDetails.value.show();
  editMode.value = editModeParam;

  if (editModeParam === true) {
    formData.value = {
      markup_id: editDetails.id,
      package_type_id: editDetails.package_type_id,
      // markup_category: null,
      markup_currency: editDetails.currency,
      markup_amount: editDetails.value,
      markup_percentage: editDetails.value,
    };
  }
  // console.log("here is the edit details", editDetails)
};

const resetForm = () => {
  formData.value = {
    package_type_id: null,
    markup_category: null,
    markup_currency: authStore.currentCurrency?.code,
    markup_amount: 0,
    markup_percentage: 0,
  };
  savePermission.value.status = false;
}


const closeModal = () => {
  modalUserDetails.value.hide();
  resetForm();
};

defineExpose({
  openModal,
  closeModal
});
</script>

<style scoped>

</style>
