<template>
  <div id="ModalKeyEdit" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ title }}</h4>
          <button
            class="btn-close"
            type="button"
            aria-label="Close"
            @click="closeModal()"
          ></button>
        </div>
        <div id="ModalBodyKeyEdit" class="modal-body">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="">Source Key:</label>
              <input
                type="text"
                class="form-control"
                disabled
                readonly
                :value="moduleName + '.' + translationData.source_text"
              />
            </div>
            <div class="col-md-12 mb-3">
              <label for="">
                Default
                <b class="text-primary">
                  ({{ translationData.default_translation_lang }}):
                </b>
              </label>
              <ckeditor
                v-model="translationData.default_translation"
                :config="{ height: 100, readOnly: true, autoParagraph: false }"
              />
            </div>
            <div class="col-md-12 mb-3">
              <label for="">Translation:</label>
              <ckeditor
                v-model="translationData.translation"
                :config="{ autoParagraph: false, height: 100 }"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal()">
            {{ $translate('common.close') }}
          </button>
          <button
            type="button"
            @click="saveTranslation()"
            class="btn btn-primary"
          >
            {{ $translate('common.save_changes') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, watch } from 'vue';
  import { Modal } from 'bootstrap';
  import { $translate } from '@/core/services/translator.service';

  const emit = defineEmits(['saveTranslation']);

  const props = defineProps({
    moduleName: {
      type: String,
      required: true,
    },
  });

  const modalKeyEditForm = ref(null);
  const translationData = ref({});

  onMounted(() => {
    modalKeyEditForm.value = new Modal(
      document.getElementById('ModalKeyEdit'),
      {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
      }
    );
  });

  const title = ref('');
  const editMode = ref(false);

  watch(editMode, (newValue) => {
    if (newValue)
      title.value =
        props.moduleName + '.' + translationData.value.source_text + ' - Edit';
    else title.value = 'Create';
  });

  const openModal = (rowData, paramEditMode) => {
    translationData.value = rowData.row.data;
    editMode.value = paramEditMode;
    modalKeyEditForm.value.show();
  };

  const closeModal = () => {
    modalKeyEditForm.value.hide();
  };

  const saveTranslation = () => {
    emit('saveTranslation', translationData.value);
    closeModal();
  };

  defineExpose({
    openModal,
    closeModal,
  });
</script>
