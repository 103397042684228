<template>

  <DxDataGrid
      ref="permissionDxGrid"
      :show-borders="true"
      :data-source="drpStore.permission_tree"
      :show-row-lines="true"
      :show-column-lines="true"
      :column-auto-width="true"
      :remote-operations="false"
      :hover-state-enabled="true"
      :allow-column-resizing="true"
      :column-resizing-mode="'widget'"
      @selection-changed="onSelectionChanged"
      @row-dbl-click="onRowDblClick"
      @content-ready="onContentReady"
      :width="'100%'"
      style="zoom: .75"
      :height="700"
      :no-data-text="'Lütfen izine sahip bir alt modül seçiniz.'"
  >


    <DxColumn width="70" data-field="id" caption="ID"></DxColumn>
    <DxColumn data-field="name" caption="İzin" cell-template="permissionNameTemplate"></DxColumn>
    <template #permissionNameTemplate="{ data: rowInfo }">
      <label
          :for="'checkbox-primary-' + rowInfo.data.id"
          :class="rowInfo.data.role_has_permission ? 'text-success' : 'text-danger'" class="fw-bolder cursor-pointer"
      >{{ rowInfo.data.sub_module_access ? 'Alt Modül Erişim İzini' : rowInfo.data.name }}</label>
    </template>

    <DxColumn data-field="number_of_agencies_with_permits" caption="Agency" width="70"></DxColumn>
    <DxColumn data-field="number_of_users_with_permits" caption="User" width="70"></DxColumn>

    <DxColumn data-field="description" caption="Açıklama"></DxColumn>

    <DxSelection
        :select-all-mode="allMode"
        :show-check-boxes-mode="checkBoxesMode"
        mode="multiple"
    />

    <DxSearchPanel
        :visible="true"
        :highlight-case-sensitive="true"
    />

    <DxToolbar>
      <DxItem location="after">
        <button @click="savePermission()" class="btn btn-primary">
          Kaydet <i style="margin-left: 10px;" class="ml-2 fa fa-save"></i>
        </button>
      </DxItem>
      <DxItem name="exportButton"/>
      <DxItem name="searchPanel"/>
    </DxToolbar>

  </DxDataGrid>

  <PermissionDetail ref="permissionDetailRef"/>
</template>

<script setup>
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxToolbar,
  DxSelection,
  DxItem,
  DxPager,
} from 'devextreme-vue/data-grid';
import {
  dxPageSizes,
} from "@/core/services/devextreme.datagrid.service";
import {useDefaultRolePermissionStore} from "@/stores/SystemConfiguration/defaultRolePermissionStore";
import {ToastMessage} from "@/core/services/general.service";
import {ref, watch} from "vue";
import PermissionDetail from "@/components/configuration/DefaultRolePermission/PermissionDetail/PermissionDetail.vue";

const permissionDetailRef = ref(null);

const permissionDxGrid = ref(null);
const initialData = ref([]);
const drpStore = useDefaultRolePermissionStore();


const onContentReady = (e) => {
  const data = e.component.getDataSource().items();
  const indexesToSelect = [];

  // Verilerin ilk halini sakla
  initialData.value = JSON.parse(JSON.stringify(data));

  data.forEach((item, index) => {
    if (item.role_has_permission) {
      indexesToSelect.push(index);
    }
  });

  if (indexesToSelect.length > 0) {
    e.component.selectRowsByIndexes(indexesToSelect);
  }
};

const onSelectionChanged = (e) => {
  const data = e.component.getDataSource().items();

  if (e.selectedRowKeys && e.selectedRowKeys.length) {
    e.selectedRowKeys.forEach(key => {
      const rowData = data.find(item => item.id === key);
      if (rowData) {
        rowData.changed = 1;
      }
    });
  }

  if (e.deselectedRowKeys && e.deselectedRowKeys.length) {
    e.deselectedRowKeys.forEach(key => {
      const rowData = data.find(item => item.id === key);
      if (rowData) {
        rowData.changed = 1;
      }
    });
  }

  //console.log("Updated data:", data);
};


const savePermission = async () => {

  // console.log("selected ", permissionDxGrid.value.instance.getSelectedRowKeys());
  const permissions = [];
  const selectedKeys = permissionDxGrid.value.instance.getSelectedRowKeys();

  // console.log("selectedKeys structure: ", selectedKeys);

  drpStore.permission_tree.forEach(item => {
    const isSelected = selectedKeys.some(selected => selected.id === item.id);
    permissions.push({
      id: item.id,
      value: isSelected
    });
  });

  //console.log("permissions ", permissions);

  if (permissions.length === 0) {
    ToastMessage('error', 'İşlem yapılacak izin bulunamadı!', 'Hata!');
    return false;
  }

  try {
    const response = await drpStore.savePermissions(permissions);

    if (response?.status === 200) {
      ToastMessage('success', response.data.message, 'Successfully!');
    }

  } catch (e) {
    console.error("error at save permission", e);
  }
}


const onRowDblClick = (e) => {
  const rowData = e.data;
  permissionDetailRef.value.getPermissionDetailsToModal(e.data.id)
};
</script>


<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>

