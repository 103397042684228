<template>
  <div class="page-title">
    <div class="row">
      <div class="col-6">
        <h3>{{ main }}</h3>
      </div>
      <div class="col-6">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ path: '/' }">
              <svg class="stroke-icon">
                <use href="@/assets/svg/icon-sprite.svg#stroke-home"></use>
              </svg>
            </router-link>
          </li>
          <template v-if="breadcrumbItems.length">
            <li
              v-for="(item, index) in breadcrumbItems"
              :key="index"
              :class="{ active: item.active }"
              class="breadcrumb-item"
            >
              <template v-if="!item.active">
                <router-link :to="{ path: item.path }">{{ item.name }}</router-link>
              </template>
              <template v-else>
                {{ item.name }}
              </template>
            </li>
          </template>
          <template v-else>
            <li class="breadcrumb-item active">{{ main }}</li>
          </template>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    main: {
      type: String,
      default: "",
    },
    breadcrumbItems: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.breadcrumb-item.active {
  font-weight: bold;
}
</style>
