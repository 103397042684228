import {apiClient} from "@/core/common/http-common";

export default class AuthService {
    async login(credentials) {
        return await apiClient.post('/login', credentials);
    }

    async logout() {
        return await apiClient.post('/logout');
    }

    async authControl() {
        return await apiClient.get('/user');
    }
}

export const authService = new AuthService();