<template>
  <div class="container-fluid">
    <DxDataGrid
        :data-source="moduleGroups"
        key-expr="id"
        :show-borders="true"
        :height="440"
        width="100%"
    >
      <DxRowDragging
          :allow-reordering="true"
          :on-reorder="onReorder"
          :show-drag-icons="true"
      />
      <DxSorting mode="none"/>
      <DxScrolling mode="virtual"/>
      <DxColumn
          :width="55"
          data-field="id"
      />
      <DxColumn
          :width="150"
          data-field="name"
          caption="Module Group Name"
      />

      <DxToolbar>
        <DxItem location="after">
          <button @click="saveSorting()" class="btn btn-primary">
            Kaydet <i style="margin-left: 10px;" class="ml-2 fa fa-save"></i>
          </button>
        </DxItem>
      </DxToolbar>
    </DxDataGrid>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import {
  DxDataGrid, DxColumn, DxScrolling, DxRowDragging, DxSorting, DxItem, DxToolbar,
} from 'devextreme-vue/data-grid';
import {useModuleSortingStore} from '@/stores/SystemConfiguration/moduleSortingStore';
import {ToastMessage} from "@/core/services/general.service";

const moduleGroups = ref([]);

const moduleSortingStore = useModuleSortingStore();

onMounted(async () => {
  await getModuleGroups();
});

const getModuleGroups = async () => {
  await moduleSortingStore.getModuleGroups();
  moduleGroups.value = moduleSortingStore.moduleGroups;
}

const onReorder = async (e) => {
  const visibleRows = e.component.getVisibleRows();
  const toIndex = moduleGroups.value.findIndex((item) => item.id === visibleRows[e.toIndex].data.id);
  const fromIndex = moduleGroups.value.findIndex((item) => item.id === e.itemData.id);
  const newModuleGroups = [...moduleGroups.value];

  newModuleGroups.splice(fromIndex, 1);
  newModuleGroups.splice(toIndex, 0, e.itemData);
  moduleGroups.value = newModuleGroups;


};

const saveSorting = async () => {
  try {
    let response = await moduleSortingStore.saveOrderModuleGroups(moduleGroups.value)
    if (response?.status === 200) {
      ToastMessage('success', response.data.message, 'Successfully!');
      await getModuleGroups();
    }
  } catch (e) {
    throw e;
  }
}
</script>

<style>
.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  width: 24%;
  display: inline-block;
  margin-top: 10px;
}
</style>
