import { apiClient, apiClientFullURL } from "@/core/common/http-common";

export default class TranslationService {
    const
    base_url = apiClientFullURL;

    getLangUrl(dgx) {
        return this.base_url + `/__GET_LANG?dgx=${dgx}`;
    }

    translationListUrl() {
        return this.base_url + "/translation";
    }

    moduleDetailsLoad(file_name) {
        return this.base_url + `/translation/${file_name}/show`;
    }

    moduleDetailsUpdateOrCreate(file_name) {
        return this.base_url + `/translation/${file_name}/updateOrCreate`;
    }


    async updateModule(file_name, data) {
        return await apiClient.post(`/translation/${file_name}/updateOrCreate`, data);
    }

}

export const translationService = new TranslationService();