import {apiClient, apiClientFullURL} from "@/core/common/http-common";

export default class SystemConfigurationService {

    const
    base_url = apiClientFullURL;

    rolesResource() {
        return this.base_url + "/SystemConfiguration/Roles";
    }

    moduleGroupResource() {
        return this.base_url + "/SystemConfiguration/ModuleGroups";
    }

    moduleResource() {
        return this.base_url + "/SystemConfiguration/Modules";
    }

    subModuleResource() {
        return this.base_url + "/SystemConfiguration/SubModules";
    }

    allPermissionResource() {
        return this.base_url + "/SystemConfiguration/Permissions";
    }

    async getRoleGroups() {
        return await apiClient.get('/SystemConfiguration/Get/RoleGroups');
    }

    async getModuleGroups() {
        return await apiClient.get('/SystemConfiguration/Get/ModuleGroups');
    }

    async createModule(formData) {
        return await apiClient.post('/SystemConfiguration/Modules', formData);
    }

    async editModule(formData) {
        return await apiClient.put(`/SystemConfiguration/Modules/${formData.id}`, formData);
    }

    async getModules() {
        return await apiClient.get('/SystemConfiguration/Get/Modules');
    }

    async getSubModules() {
        return await apiClient.get('/SystemConfiguration/Get/SubModules');
    }

    async createSubModule(formData) {
        return await apiClient.post('/SystemConfiguration/SubModules', formData);
    }

    async editSubModule(formData) {
        return await apiClient.put(`/SystemConfiguration/SubModules/${formData.id}`, formData);
    }

    async createPermission(formData) {
        return await apiClient.post('/SystemConfiguration/Permissions', formData);
    }

    async editPermission(formData) {
        return await apiClient.put(`/SystemConfiguration/Permissions/${formData.id}`, formData);
    }

    // Sorting Transactions
    async saveOrderModuleGroups(orderData) {
        return await apiClient.post(`/SystemConfiguration/Sorting/Save/ModuleGroups`, orderData);
    }

    async getModulesOfModuleGroup(moduleGroupId) {
        return await apiClient.get(`/SystemConfiguration/Get/ModulesOfModuleGroups?moduleGroupId=${moduleGroupId}`);
    }

    async saveOrderModules(orderData) {
        return await apiClient.post(`/SystemConfiguration/Sorting/Save/Modules`, orderData);
    }

    async getSubModulesAndOthers(moduleGroupId) {
        return await apiClient.get(`/SystemConfiguration/Get/SubModuleAndOthers`);
    }

    async saveOrderSubModules(orderData) {
        return await apiClient.post(`/SystemConfiguration/Sorting/Save/SubModules`, orderData);
    }

    async getAllRolePermissionData() {
        return await apiClient.get('/SystemConfiguration/Get/AllRolePermission');
    }

    async getRoles() {
        return await apiClient.get('/SystemConfiguration/Get/Roles');
    }

    async getModuleTreeFroRole(role_id, model) {
        return await apiClient.get('/SystemConfiguration/Get/ModuleTreeForRole?role_id=' + role_id + '&model=' + model);
    }

    async getSubModuleTree(module_id, role_id, model) {
        return await apiClient.get('/SystemConfiguration/Get/SubModuleTree?module_id=' + module_id + '&role_id=' + role_id + '&model=' + model);
    }

    async getPermissionTree(sub_module_id, role_id, model) {
        return await apiClient.get('/SystemConfiguration/Get/PermissionTree?sub_module_id=' + sub_module_id + '&role_id=' + role_id + '&model=' + model);
    }

    async savePermissions(data) {
        return await apiClient.post('/SystemConfiguration/SavePermission', data);
    }

    async getPermissionDetails(permission_id) {
        return await apiClient.get('/SystemConfiguration/Get/PermissionDetails?permission_id=' + permission_id);
    }

    async deletePermission(permission_id, model_id, model_type) {
        return await apiClient.delete('/SystemConfiguration/DeleteModelPermission', {
            data: {
                permission_id, model_id, model_type,
            }
        });
    }

    async getTheModelsWhoMayBeAddedToPermission(permission_id, model) {
        return await apiClient.get('/SystemConfiguration/Get/TheModelsWhoMayBeAddedToPermission?permission_id=' + permission_id + '&model=' + model);
    }

    async saveModelsPermission(permission_id, models, model_type) {
        return await apiClient.post('/SystemConfiguration/SaveModelsPermission', {permission_id, models, model_type});
    }

    /**
     * Currency Module
     */

    currenciesResource() {
        return this.base_url + "/SystemConfiguration/Currencies";
    }
}

export const systemConfigurationService = new SystemConfigurationService();