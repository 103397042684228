import {apiClient} from "@/core/common/http-common";

export default class LocationService {

    async getCountries() {
        return await apiClient.get(`/Locations/Get/Countries`);
    }

    async getStates(country_id) {
        return await apiClient.get(`/Locations/Get/States?country_id=${country_id}`);
    }

    async getCities(state_id) {
        return await apiClient.get(`/Locations/Get/Cities?state_id=${state_id}`);
    }
}

export const locationService = new LocationService();