<template>
  <div class="container-fluid">
    <Breadcrumbs :main="'Translation'" />
    <div class="row starter-main">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h5>Translation List</h5>
            <div class="card-header-right">
              <select
                v-model="selectedLanguage"
                @change="onLanguageChange"
                class="form-control"
              >
                <option value="en">EN</option>
                <option value="tr">TR</option>
                <option value="es">ES</option>
                <option value="ru">RU</option>
                <option value="ar">AR</option>
              </select>
            </div>
          </div>
          <div class="card-body">
            <DxDataGrid
              ref="dataGridRef"
              :show-borders="true"
              :data-source="dataSource"
              :column-auto-width="true"
              :show-row-lines="true"
              :show-column-lines="true"
              :row-alternation-enabled="true"
              :remote-operations="false"
              :allow-column-resizing="true"
              :column-resizing-mode="'widget'"
              :width="'100%'"
              :height="700"
              @toolbarPreparing="dxOnToolbarPreparing"
              @contextMenuPreparing="
                (e) => dxOnContextMenuPreparing(e, customContexts)
              "
              @exporting="
                (e) =>
                  dxOnExcelExporting(e, 'Translation List', 'Translation List')
              "
            >
              <DxExport :enabled="true" :allow-export-selected-data="false" />
              <DxPaging :page-size="500" />
              <DxPager
                :visible="true"
                :allowed-page-sizes="dxPageSizes"
                :display-mode="'compact'"
                :show-page-size-selector="true"
                :show-info="true"
                :show-navigation-buttons="true"
              />

              <DxColumn data-field="fileName" caption="Module Name" />
              <DxColumn data-field="language" caption="Language" />

              <DxColumn
                data-field="totalFilledKeys"
                caption="Number Of Content"
                cell-template="totalFilledKeysTemplate"
              />
              <template #totalFilledKeysTemplate="{ data: rowInfo }">
                <b class="text-primary">
                  {{ rowInfo.data.totalKeys + ' Entries, ' }}
                </b>
                /
                <b class="text-info">
                  {{ rowInfo.data.totalKeys + ' Filled.' }}
                </b>
              </template>

              <DxColumn
                data-field="percent"
                align
                caption="Percent"
                cell-template="percentTemplate"
              />
              <template #percentTemplate="{ data: rowInfo }">
                <div class="d-flex align-items-center justify-content-center">
                  <b
                    :class="
                      rowInfo.data.percent == 100
                        ? 'text-success'
                        : 'text-danger'
                    "
                  >
                    %{{ rowInfo.data.percent }}
                  </b>
                </div>
              </template>

              <DxFilterRow :visible="true" />
              <DxHeaderFilter :visible="true" />

              <DxColumn
                data-field="id"
                caption="Actions"
                cell-template="detailButtonTemplate"
              />

              <template #detailButtonTemplate="{ data: rowInfo }">
                <router-link
                  :to="{
                    name: 'translation_module_detail',
                    params: {
                      module_name: rowInfo.data.fileName,
                      selected_language: selectedLanguage,
                    },
                  }"
                >
                  <button class="btn btn-primary btn-sm">
                    <i class="fa fa-edit ms-1"></i>
                  </button>
                </router-link>
              </template>

              <DxSearchPanel :visible="true" :highlight-case-sensitive="true" />
              <DxColumnChooser :enabled="true" />

              <DxGroupPanel :visible="true" />
              <DxGrouping :auto-expand-all="true" />

              <DxToolbar>
                <DxItem name="columnChooserButton" />
                <DxItem name="exportButton" />
                <DxItem name="searchPanel" />
                <DxItem name="groupPanel" />
              </DxToolbar>

              <DxSummary>
                <DxGroupItem
                  column="fileName"
                  summary-type="count"
                  :display-format="
                    $translate('agencies.management.summery_count1', {
                      zero: '{0}',
                    })
                  "
                />
                <DxTotalItem
                  column="title"
                  summary-type="count"
                  :display-format="
                    $translate('agencies.management.summery_count2', {
                      zero: '{0}',
                    })
                  "
                />
              </DxSummary>
            </DxDataGrid>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {
    DxDataGrid,
    DxColumn,
    DxExport,
    DxFilterRow,
    DxHeaderFilter,
    DxSearchPanel,
    DxPaging,
    DxPager,
    DxToolbar,
    DxItem,
    DxColumnChooser,
    DxGroupPanel,
    DxGrouping,
    DxSummary,
    DxGroupItem,
    DxTotalItem,
  } from 'devextreme-vue/data-grid';
  import { createStore } from 'devextreme-aspnet-data-nojquery';
  import { translationService } from '@/core/api/translation.api';
  import {
    dxOnExcelExporting,
    dxOnContextMenuPreparing,
    dxPageSizes,
    dxOnToolbarPreparing,
    dxOnBeforeSendFunction,
    dxOnAjaxErrorHandler,
  } from '@/core/services/devextreme.datagrid.service';
  import { $translate } from '@/core/services/translator.service';

  import { ref, watch } from 'vue';

  const customContexts = [
    {
      text: 'Synchronise Whole System Language',
      icon: 'warning',
      onItemClick: () => {
        let confirmResult = confirm(
          'This process may take a while, do you still want to continue?'
        );
        if (confirmResult) alert('coming soon!');
      },
    },
  ];

  const dataGridRef = ref();
  const selectedLanguage = ref('en');

  const createDataSource = () => {
    const url =
      translationService.translationListUrl() +
      '?selectLanguage=' +
      selectedLanguage.value;
    return createStore({
      key: '',
      loadUrl: url,
      insertUrl: `${url}/insert`,
      updateUrl: `${url}/update`,
      deleteUrl: `${url}/delete`,
      onBeforeSend: dxOnBeforeSendFunction,
      onAjaxError: dxOnAjaxErrorHandler,
    });
  };

  const dataSource = ref(createDataSource());

  const onLanguageChange = () => {
    dataSource.value = createDataSource();
    dataGridRef.value.instance.refresh();
  };

  watch(selectedLanguage, onLanguageChange);
</script>
