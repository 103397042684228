import { configure, defineRule } from "vee-validate";

configure({
    validateOnInput: true,
});

defineRule('required', (value, _, context) => {
    if (value === null || value === undefined || value === '') {
        return `"${context.field}" is a required field.`;
    }

    if (typeof value === 'string' && value.trim() === '') {
        return `"${context.field}" is a required field.`;
    }

    return true;
});

defineRule('email', (value, _, context) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
        return `"${context.field}" must be a valid email address.`;
    }
    return true;
});

defineRule('numeric', (value, _, context) => {
    if (isNaN(value)) {
        return `"${context.field}" must be a valid number.`;
    }
    return true;
});