import { defaultLocale, localeOptions } from "@/core/constants/config";
import { createI18n } from "vue-i18n";
import { translationService } from "@/core/api/translation.api";

// Dil dosyalarını dinamik olarak yüklemek için fonksiyon
async function loadLocaleMessages(locale) {
  let messages = {};
  try {
    const dgx = localStorage.getItem("dgx");
    const f_dgx = localStorage.getItem("f_dgx");
    const garbage = localStorage.getItem("garbage");
    // console.log("gar", garbage);
    if (garbage === null || dgx !== f_dgx) {
      const response = await fetch(translationService.getLangUrl(dgx), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const allData = await response.json();
        if (allData) {
          //console.log("we are clearing dgx");
          messages = allData.data;
          localStorage.setItem("garbage", JSON.stringify(messages));
          localStorage.setItem("dgx", allData.dgx); // Bu satır dgx anahtarını güncelleyecektir
        } else {
          console.error(`No messages found for locale: ${locale}`);
        }
      } else {
        console.error(`Could not load messages for locale: ${locale}`);
      }
    } else {
      if (garbage) {
        messages = JSON.parse(garbage);
      } else {
        console.error(`No messages found in localStorage for locale: ${locale}`);
      }
    }
  } catch (error) {
    console.error(`Error loading messages for locale: ${locale}`, error);
  }
  return messages;
}

// localStorage'dan dili alma ve geçerli bir dil olup olmadığını kontrol etme
const storedLanguage = localStorage.getItem("currentLanguage");
const isValidLanguage = localeOptions.some((option) => option.id === storedLanguage);
const locale = isValidLanguage ? storedLanguage : defaultLocale;

// Vue I18n'i dinamik olarak yüklenen mesajlarla başlatma
export async function createI18nInstance() {
  const messages = await loadLocaleMessages(locale);
  return createI18n({
    locale: locale,
    fallbackLocale: "en",
    messages,
  });
}

const i18n = await createI18nInstance();

i18n.global.setLocaleMessage = async (locale) => {
  const messages = await loadLocaleMessages(locale);
  if (messages[locale]) {
    // console.log("here is the ", locale);

    i18n.global.setLocaleMessage(locale, messages[locale]);
    i18n.global.locale.value = locale;
    localStorage.setItem("currentLanguage", locale);
  } else {
    console.error(`Could not set locale messages for locale: ${locale}`);
  }
};

export default i18n;
