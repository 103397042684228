<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div>
                <a class="logo">
                  <img class="img-fluid for-light" :src="require('@/assets/images/b2b/logo-white-mode.svg')" alt="looginpage" />
                  <img class="img-fluid for-dark" :src="require('@/assets/images/b2b/logo-dark-mode.svg')" alt="looginpage" />
                </a>
              </div>
              <div class="login-main">
                <form @submit.prevent="handleLogin()" class="theme-form">
                  <h4>Sign in to account</h4>
                  <p>Enter your email & password to login</p>
                  <div class="form-group">
                    <label class="col-form-label">Email Address</label>
                    <input
                      class="form-control"
                      type="email"
                      required=""
                      placeholder="Enter your email address"
                      v-model="credentials.email"
                    />
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <div class="form-input position-relative">
                      <input
                        class="form-control"
                        :type="showPassword ? 'text' : 'password'"
                        name="login[password]"
                        required=""
                        placeholder="*********"
                        v-model="credentials.password"
                      />

                      <div class="show-hide" @click="togglePassword()">
                        <span :class="{ show: !showPassword, hide: showPassword }"></span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="checkbox p-0">
                      <input id="checkbox1" type="checkbox" v-model="credentials.remember_me" />
                      <label class="text-muted" for="checkbox1">Remember me</label>
                    </div>
                    <router-link class="link" to="/">Forgot password?</router-link>
                    <div class="text-end mt-3">
                      <button :disabled="loginBtnDisabled" class="btn btn-primary btn-block w-100" type="submit">
                        Sign In
                      </button>

                      <!-- <button type="button" @click="trans()">rts</button> -->
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import { useAuthStore } from "@/stores/authStore";
import { ToastMessage } from "@/core/services/general.service";
import { $translate } from "@/core/services/translator.service";

const credentials = reactive({
  email: "",
  password: "",
  remember_me: false,
});

const showPassword = ref(false);
const loginBtnDisabled = ref(false);

const togglePassword = () => {
  showPassword.value = !showPassword.value;
};

const handleLogin = async () => {
  loginBtnDisabled.value = true;
  const authStore = useAuthStore();
  try {
    await authStore.login(credentials);
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      ToastMessage("error", error.response.data.message, "Hata!");
    } else {
      console.log("error at login", error);
      ToastMessage("error", "An unexpected error occurred.", "Hata!");
    }
    loginBtnDisabled.value = false;
  }
};

const trans = () => {
  let string = $translate("system.messages.login_successfully_title");
  console.log("here is the click translation", string);
};

//console.log("big => ", $translate('system.C8H9fyqrfMOp\/6ucGTOUIIwzF7YIKyjAAqlPoPygfa8=.hnDKeIs9wEp7\/RQkcWzMeSqmHiLHu4tk+6IXaAs4f2dDG3XHVK+lzQYoBfUoBDSM', false));
</script>
