<template>

  <DxDataGrid
      :show-borders="true"
      :data-source="drpStore.module_tree"
      :show-row-lines="true"
      :show-column-lines="true"
      :column-auto-width="true"
      :remote-operations="false"
      :hover-state-enabled="true"
      :allow-column-resizing="true"
      @selection-changed="onSelectionChanged"
      :column-resizing-mode="'widget'"
      :width="'100%'"
      style="zoom: .75"
      :height="700"
      :no-data-text="'Lütfen bir yetki seçin'"
  >
    <DxSelection mode="single"/>


    <DxColumn width="110" data-field="module_group_name" caption="Grup Adı"></DxColumn>
    <DxColumn width="250" data-field="module_name" caption="Modül Adı" cell-template="iconPreviewTemplate"></DxColumn>
    <template #iconPreviewTemplate="{ data: rowInfo }">
      <div class="flex justify-center container-select-module">
        <i class="sidebar-module-icon" :class="rowInfo.data.module_ico"></i>
        <b :class=" rowInfo.data.has_permissions_count ? 'text-success': 'text-danger'"
        >{{ rowInfo.data.module_name }}</b>
      </div>
    </template>

    <DxColumn data-field="description" caption="Açıklama" cell-template="descriptionTemplate"></DxColumn>
    <template #descriptionTemplate="{ data: rowInfo }">
      <div class="flex justify-center">
        <p>
          <b class="text-primary">{{ rowInfo.data.sub_module_count }}</b>/<b
            class="text-success">{{ rowInfo.data.has_sub_module_access_count }}</b> Alt M.,
          <b class="text-primary">{{ rowInfo.data.permission_count }}</b>/<b
              class="text-success">{{ rowInfo.data.has_permissions_count }}</b> İzin</p>
      </div>
    </template>


    <DxSearchPanel
        :visible="true"
        :highlight-case-sensitive="true"
    />
  </DxDataGrid>
</template>

<script setup>
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxRequiredRule,
  DxSelection,
  DxPager,
} from 'devextreme-vue/data-grid';
import {
  dxPageSizes,
} from "@/core/services/devextreme.datagrid.service";
import {useDefaultRolePermissionStore} from "@/stores/SystemConfiguration/defaultRolePermissionStore";
import {ref} from "vue";


const drpStore = useDefaultRolePermissionStore();


const onSelectionChanged = ({selectedRowsData}) => {
  drpStore.permission_tree = [];
  const data = selectedRowsData[0];
  if (data?.id !== undefined){
    drpStore.selected_module = data?.id;
    drpStore.getSubModuleTree(data?.id);
  }

};

</script>


<style scoped>
.container-select-module {
  cursor: pointer;
}
</style>