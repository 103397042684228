<template>
  <div v-if="selectedAgencyId">
    <DxDataGrid
        ref="dataGridRef"
        :show-borders="true"
        :data-source="dataSource"
        :show-row-lines="true"
        :show-column-lines="true"
        :column-auto-width="true"
        :row-alternation-enabled="true"
        :remote-operations="{
                  groupPaging: true,
                  paging: true,
                  filtering: true,
                  sorting: true,
                  grouping: false,
                  summary: false,
                }"
        :allow-column-resizing="true"
        :column-resizing-mode="'widget'"
        :width="'100%'"
        :height="500"
        @toolbarPreparing="(e) => dxOnToolbarPreparing(e, customToolBarOptions)"
        @contextMenuPreparing="customContextMenu"
        @exporting="(e) => dxOnExcelExporting(e, 'Yetkiler', 'Yetkiler')"
    >
      <DxExport
          :enabled="true"
          :allow-export-selected-data="false"
      />
      <DxPaging :page-size="500"/>
      <DxPager
          :visible="true"
          :allowed-page-sizes="dxPageSizes"
          :display-mode="'compact'"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
      />


      <DxColumn :allow-editing="false" data-field="currency_code" caption="Currency"/>

      <DxColumn cell-template="statusTemplate" data-field="status" caption="Status">
        <DxRequiredRule/>
        <DxLookup valueExpr="value" displayExpr="name" :data-source="activePassive()"/>
      </DxColumn>
      <template #statusTemplate="{data:rowInfo}">
        <StatusBadge :status="rowInfo.data.status"/>
      </template>

      <DxColumn :allow-editing="false" data-field="balance" caption="Balance"
                cell-template="balanceTemplate"/>
      <template #balanceTemplate="{data:rowInfo}">
        <span class="text-primary fw-bolder">{{
            priceFormatter(rowInfo.data.balance, rowInfo.data.currency_code)
          }}</span>
      </template>


      <DxColumn data-field="estimated_amount" caption="Estimated Amount"
                cell-template="estimatedAmountTemplate"/>
      <template #estimatedAmountTemplate="{data:rowInfo}">
        <span class="text-dark fw-bolder"
        >{{ priceFormatter(rowInfo.data.estimated_amount, rowInfo.data.estimated_amount_currency) }}</span>
      </template>

      <DxColumn :allow-editing="false"
                data-field="updated_at"
                caption="Last Update"
                data-type="date"
                :editor-options="dxDateEditorOptions"
                cell-template="globalupdatedAt"/>
      <template #globalupdatedAt="{data:rowInfo}">
        {{ globalMoment(rowInfo.data.updated_at, 'DD.MM.YYYY HH:mm') }}
      </template>

      <DxColumn data-field="created_at"
                data-type="date"
                caption="Created At"
                :allow-editing="false"
                sort-order="desc"
                :editor-options="dxDateEditorOptions"
                cell-template="globalCreatedAt"/>
      <template #globalCreatedAt="{data:rowInfo}">
        {{ globalMoment(rowInfo.data.created_at, 'DD.MM.YYYY HH:mm') }}
      </template>


      <DxColumn type="buttons">
        <DxButton icon="edit" @click="(e) => modalWalletFormRef.openModal(true, e.row.data)"/>
        <DxButton name="delete"/>
      </DxColumn>


      <DxFilterRow :visible="false"/>
      <DxHeaderFilter :visible="false"/>

      <DxEditing
          :allow-adding="false"
          :allow-updating="false"
          :allow-deleting="true"
          mode="row"
      />
      <DxSearchPanel
          :visible="true"
          :highlight-case-sensitive="true"
      />

      <DxSummary>
        <DxTotalItem
            column="estimated_amount"
            summary-type="sum"
            display-format="Total Estimated Amount: {0}"
            :customize-text="formatTotal"
        />
      </DxSummary>

    </DxDataGrid>


    <ModalWalletForm ref="modalWalletFormRef" @refresh-datagrid="dataGridRef.instance.refresh()"/>
    <ModalLoadBalance ref="modalLoadBalanceRef" @refresh-datagrid="dataGridRef.instance.refresh()"/>

  </div>
  <div class="d-flex align-items-center justify-content-center" v-else>
    <h1 class="text-danger text-center mt-5">Agency not found!</h1>
  </div>
</template>

<script setup>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxSearchPanel,
  DxRequiredRule,
  DxPaging,
  DxPager,
  DxExport,
  DxLookup, DxButton, DxSummary, DxGroupItem, DxTotalItem,
} from 'devextreme-vue/data-grid';
import {createStore} from 'devextreme-aspnet-data-nojquery';
import {
  dxOnExcelExporting,
  dxPageSizes,
  dxOnToolbarPreparing,
  dxDateEditorOptions,
  dxOnBeforeSendFunction,
  dxOnAjaxErrorHandler
} from "@/core/services/devextreme.datagrid.service";
import StatusBadge from "@/components/StatusBadge.vue";
import {activePassive} from "@/core/data/common";
import {globalMoment, priceFormatter, ToastMessage} from "@/core/services/general.service";
import {$translate} from "@/core/services/translator.service";
import {agencyService} from "@/core/api/agency.api";
import {useAgencyStore} from "@/stores/agencyStore";
import {computed, ref, watch} from "vue";
import ModalWalletForm from "@/components/Agency/Details/includes/Wallets/includes/ModalWalletForm.vue";
import ModalLoadBalance from "@/components/Agency/Details/includes/Wallets/includes/ModalLoadBalance.vue";

const dataGridRef = ref(null);

const modalWalletFormRef = ref(null);
const modalLoadBalanceRef = ref(null);

const formatTotal = (e) => {
  return `Est. Total: ${priceFormatter(e.value)}`;
}

const customContextMenu = (e) => {

  const regularMenu = [
    {
      text: "Yenile",
      icon: "refresh",
      onItemClick: () => {
        e.component.refresh();
      },
    },
    {
      text: "Görünümü Sıfırla",
      icon: "revert",
      onItemClick: () => {
        e.component.state(null);
        localStorage.removeItem("dataGridState");
        e.component.option("stateStoring.enabled", true);
      },
    },
  ];

  const rowData = e.row && e.row.data;
  if (!rowData) {
    e.items = [...regularMenu];
    return;
  }

  e.items = [
    ...regularMenu,
    {
      text: "Load Balance",
      icon: "fa fa-money",
      onItemClick: () => {
        modalLoadBalanceRef.value.openModal(rowData);
      },
    },
  ];
};

const customToolBarOptions = [
  {
    location: "after",
    widget: "dxButton",
    options: {
      icon: "add",
      onClick: () => {
        modalWalletFormRef.value.openModal();
      },
    },
  }
];


const agencyStore = useAgencyStore();

const selectedAgencyId = computed(() => agencyStore.selected_agency?.id);
const url = computed(() => agencyService.base_url + `/Agencies/${selectedAgencyId.value}/AgencyWallet`);

let dataSource = ref(createDataSource(url.value));

watch(selectedAgencyId, () => {
  dataSource.value = createDataSource(url.value);
});

function createDataSource(currentUrl) {
  return createStore({
    key: 'id',
    loadUrl: currentUrl,
    insertUrl: currentUrl,
    updateUrl: `${currentUrl}/update`,
    deleteUrl: `${currentUrl}/delete`,
    onBeforeSend: (method, ajaxOptions) => {
      dxOnBeforeSendFunction(method, ajaxOptions);
    },
    onAjaxError: dxOnAjaxErrorHandler,
    onInserted: (values, key) => {
      ToastMessage('success', '', $translate('common.successfully'));
    },
    onUpdated: (key, values) => {
      ToastMessage('success', '', $translate('common.successfully'));
    },
    onRemoved: (key) => {
      ToastMessage('success', '', $translate('common.successfully'));
    },
  });
}

const refreshDataGrid = () => {
  dataGridRef.value.instance.refresh();
}

defineExpose({
  refreshDataGrid
});

</script>

<style scoped>

</style>
