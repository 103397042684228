export const yesOrNoData = () => {
    return [
        {value: true, display_name: 'Yes'},
        {value: false, display_name: 'No'}
    ];
}

export const activePassive = () => {
    return [
        {value: 1, name: 'Active'},
        {value: 0, name: 'Passive'}
    ];
}