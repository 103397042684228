<template>
  <div id="ModalCalculateTestMarkup" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Calculate Test Markup</h4>
          <button
              class="btn-close"
              type="button"
              aria-label="Close"
              @click="closeModal"
          ></button>
        </div>
        <div id="ModalBodyCalculateTestMarkup" class="modal-body">
          <div class="col-sm-12 col-xl-12">
            <div class="card">
              <CurrencyPriceNumberBox label="Displayed Markup:" v-model:boxValue="priceValue" @change="onPriceChange"/>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">
            {{ $translate('common.close') }}
          </button>

          <button type="button" class="btn btn-primary" @click="handleApply">
            {{ $translate('common.apply') }}
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from 'vue';
import {Modal} from 'bootstrap';
import {$translate} from '@/core/services/translator.service';
import CurrencyPriceNumberBox from "@/components/DevExtreme/DxField/CurrencyPriceNumberBox.vue";
import {useAgencyStore} from "@/stores/agencyStore";

const priceValue = ref(0);
const agencyStore = useAgencyStore();



const onPriceChange = (newValue) => {
  // console.log('Price changed to:', newValue, priceValue.value);
};

const handleApply = () => {
  emit('apply-base-price', priceValue.value)
  closeModal()
}


const emit = defineEmits([
  'close-modal',
  'refresh-users-datagrid',
  'apply-base-price'
]);

const ModalCalculateTestMarkup = ref(null);

onMounted(() => {
  ModalCalculateTestMarkup.value = new Modal(
      document.getElementById('ModalCalculateTestMarkup'),
      {
        backdrop: false,
        keyboard: true,
      }
  );
});

const openModal = () => {
  ModalCalculateTestMarkup.value.show();
};

const closeModal = () => {
  ModalCalculateTestMarkup.value.hide();
};

defineExpose({
  openModal,
  closeModal,
});
</script>

<style scoped>
ul.nav-tabs li.nav-item {
  list-style: none;
}
</style>
