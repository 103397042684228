<template>
  <div>
    <div class="row mt-4 d-flex align-items-center justify-content-center">
      <div v-for="setting in settingsOption" :key="setting.text"
           class="col-6 col-md-6 col-lg-3 col-xl-2 mb-3 d-flex align-items-center justify-content-center">
        <button class="btn settings-button" :class="setting.class" @click="setting.cbFunction">
          <i class="fa-2x pb-3 icon" :class="setting.icon"></i>
          <span class="text">{{ setting.text }}</span>
        </button>
      </div>
    </div>
  </div>

  <ModalUserForm ref="modalUserForm" @refresh-datagrid=""/>
  <ModalModelStatus
      ref="modalModelStatus"
      :default_description_message="disabled_status_default_description_message"
      @save-status-data="(data) => handleStatusData(data)"
  />

</template>

<script setup>
import {ref, defineProps, defineEmits, computed} from 'vue';
import {ConfirmWithSwal, SwalClose, SwalLoading, ToastMessage} from "@/core/services/general.service";
import {useUserStore} from "@/stores/userStore";
import {errorService} from "@/core/services/error.service";
import ModalUserForm from "@/pages/users/management/modals/ModalUserForm.vue";
import {$translate} from "@/core/services/translator.service";
import ModalModelStatus from "@/components/DetailModals/ModalModelStatus.vue";

const modalModelStatus = ref(null);
const modalUserForm = ref(null);
const userStore = useUserStore();

const props = defineProps({
  user: {
    type: Object,
    required: true,
  }
});

const disabled_status_default_description_message = $translate(
    'users.management.messages.frontend.disabled_status_default_description_message'
);


const emit = defineEmits(['user-details-refresh']);

const deleteUser = async () => {

  let confirmResult = await ConfirmWithSwal(
      'warning',
      $translate('users.management.messages.frontend.delete_confirm_text'),
      $translate('users.management.messages.frontend.delete_confirm_title')
  );

  if (!confirmResult) return false;

  SwalLoading(
      'info',
      $translate('users.management.messages.frontend.delete_wait_text'),
      $translate('common.please_wait')
  );

  try {
    const response = await userStore.deleteUser(props.user.id);
    ToastMessage('success', response.data.message, $translate('common.successfully'));
    emit('user-details-refresh', props.user.id);
  } catch (e) {
    errorService.apiClientError(e);
  } finally {
    SwalClose();
  }

  // console.log('Delete user function called', props.user?.id);
};

const resetPassword = async () => {
  let confirmResult = await ConfirmWithSwal(
      'warning',
      $translate('users.management.messages.frontend.reset_confirm_text'),
      $translate('users.management.messages.frontend.reset_confirm_title')
  );
  if (!confirmResult) return false;

  SwalLoading(
      'info',
      $translate('users.management.messages.frontend.reset_confirm_wait_text'),
      $translate('common.please_wait')
  );

  try {
    const response = await userStore.resetPassword(props.user.id);
    ToastMessage('success', response.data.message, $translate('common.successfully'));

  } catch (e) {
    errorService.apiClientError(e);
  } finally {
    SwalClose();
  }
};

const openModalModelStatus = () => {
  if (modalModelStatus.value) {
    modalModelStatus.value.openModal(props.user?.status, props.user?.status_description,)
  }
};

const handleStatusData = async (data) => {
  // console.log("here is the main component data", data);

  SwalLoading(
      'info',
      $translate('users.management.messages.frontend.making_status_change'),
      $translate('common.please_wait')
  );

  try {
    const response = await userStore.changeStatus({user_id: props.user.id, ...data});
    ToastMessage('success', response.data.message, $translate('common.successfully'));
    emit('user-details-refresh', props.user.id);
    modalModelStatus.value.closeModal();
  } catch (e) {
    errorService.apiClientError(e, true, true);
  } finally {
    SwalClose();
  }
}


const settingsOption = [
  {
    text: $translate('users.management.details_modal.tabs.settings.virtual_login'),
    icon: 'fa fa-send-o',
    class: 'btn-outline-primary',
    cbFunction: () => {
    },
  },
  {
    text: $translate('users.management.details_modal.tabs.settings.status'),
    icon: 'fa fa-lock',
    class: 'btn-outline-secondary',
    cbFunction: openModalModelStatus,
  },
  {
    text: $translate('users.management.details_modal.tabs.settings.reset_password'),
    icon: 'fa fa-refresh',
    class: 'btn-outline-success',
    cbFunction: resetPassword,
  },
  {
    text: $translate('users.management.details_modal.tabs.settings.delete'),
    icon: 'fa fa-trash',
    class: 'btn-outline-danger',
    cbFunction: deleteUser,
  },
];
</script>

<style lang="scss" scoped>
.settings-button {
  font-size: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
}

.icon {
  display: block;
  margin-bottom: 0.5rem;
}

.text {
  display: block;
}
</style>
