<template>
  <div>
    <DxTagBox
        ref="dxTagBox"
        :items="items"
        :display-expr="displayExpr"
        :value-expr="valueExpr"
        :value="fieldValue"
        :class="[{ 'is-invalid': meta.error }, 'form-control']"
        @value-changed="onValueChanged"
        :input-attr="{ 'aria-label': 'Languages' }"
        :show-selection-controls="true"
        :search-enabled="searchEnabled"
    />
    <ErrorMessage :name="name" class="invalid-feedback"/>
  </div>
</template>

<script setup>
import {ref, watch, onMounted, onBeforeUnmount, nextTick} from 'vue';
import {useField, ErrorMessage} from 'vee-validate';
import DxTagBox from "devextreme-vue/tag-box";


const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  items: {
    type: Array,
    required: true,
  },
  displayExpr: {
    type: String,
  },
  valueExpr: {
    type: String,
  },
  rules: {
    type: String,
    default: 'required',
  },
  modelValue: {
    type: Array,
    default: () => null,
  },
  searchEnabled: {
    type: Boolean,
    default: true,
  }
});

const emit = defineEmits(['update:modelValue']);

const {value: fieldValue, meta, setTouched} = useField(props.name, props.rules);
const dxTagBox = ref(null);

const onValueChanged = (e) => {
  const value = e.component.option("value");
  if (JSON.stringify(fieldValue.value) !== JSON.stringify(value)) {
    fieldValue.value = value;


    // console.log("here", (value.length === 0 ? null : value))
    emit('update:modelValue', (value.length === 0 ? null : value));
  }
};


// Initialize the component
onMounted(() => {
  if (props.modelValue?.length > 0) {
    fieldValue.value = props.modelValue;
  }
});

// Watch for changes in modelValue and update the component value

watch(() => props.modelValue, (newValue) => {
  if (JSON.stringify(fieldValue.value) !== JSON.stringify(newValue)) {
    nextTick(() => {
      fieldValue.value = newValue;
    });
  }
});


onBeforeUnmount(() => {
  // Cleanup if necessary
});
</script>

<style scoped>
.invalid-feedback {
  display: block;
}
</style>
