<template>
  <div>
    <div class="container">
      <br>
      <br>
      <br>
      <div id="cardElement" class="card m-4 mt-5">
        <button class="btn btn-primary" @click="blockCard">Block Card</button>
        <br>
        <br>
        <button class="btn btn-primary" @click="blockPage">Block All Page</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { BlockContainer, BlockFullPage, UnBlocker } from "@/core/services/general.service";

const blockCard = () => {
  const loader = BlockContainer('cardElement');
  setTimeout(() => {
    UnBlocker(loader);
  }, 2000);
};

const blockPage = () => {
  const loader = BlockFullPage();
  setTimeout(() => {
    UnBlocker(loader);
  }, 2000);
};
</script>
