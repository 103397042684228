<template>
  <div>
    <div class="customizer-links" :class="{ open: customizer }">
      <div class="nav flex-column nac-pills" id="c-pills-tab">
        <CustomizerSetting/>
      </div>
    </div>
    <div class="customizer-contain" :class="{ open: customizer }">
      <div class="tab-content">
        <CustomizerConfiguration/>
        <div class="customizer-body custom-scrollbar">
          <Customlayout/>
          <CustomSetting/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import CustomizerSetting from './customizersetting';
import CustomizerConfiguration from './customizerconfiguration.vue';
import Customlayout from './customlayout.vue';


export default {
  name: 'customizer',
  components: {
    Document,
    CustomizerSetting,
    CustomizerConfiguration,
    Customlayout,
  },
  computed: {
    ...mapState({
      customizer: (state) => state.menu.customizer,
    }),
  },
};
</script>