<template>
  <div id="ModalLoadBalance" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Load Balance</h4>
          <button
              class="btn-close"
              type="button"
              aria-label="Close"
              @click="closeModal"
          ></button>
        </div>
        <div id="ModalBodyLoadBalance" class="modal-body">

          <div class="row">
            <div class="col-md-6 mb-4">
              <DxSelectBox
                  label="Currency:"
                  labelMode="outside"
                  v-model="formData.currency"
                  :items="authStore.currencies"
                  displayExpr="code"
                  valueExpr="code"
                  :read-only="true"
              >
              </DxSelectBox>
            </div>

            <div class="col-md-6 mb-4">

              <CurrencyPriceNumberBox v-model:boxValue="formData.amount"  label="Amount" label-mode="outside" :currency="formData.currency"/>
            </div>

            <div class="col-md-12 mb-4">
              <DxTextBox
                  label="Description"
                  labelMode="outside"
                  v-model="formData.description"
              />
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">
            {{ $translate('common.close') }}
          </button>
          <button type="button" class="btn btn-primary" @click="handleSave">
            {{ $translate('common.save_changes') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {Modal} from 'bootstrap';
import {$translate} from "@/core/services/translator.service";
import DxSelectBox from "devextreme-vue/select-box";
import {useAuthStore} from "@/stores/authStore";
import {useAgencyStore} from "@/stores/agencyStore";
import {errorService} from "@/core/services/error.service";
import {BlockContainer, ToastMessage, UnBlocker} from "@/core/services/general.service";
import {bool} from "yup";
import CurrencyPriceNumberBox from "@/components/DevExtreme/DxField/CurrencyPriceNumberBox.vue";
import DxTextBox from "devextreme-vue/text-box";

const authStore = useAuthStore();
const agencyStore = useAgencyStore();
const emit = defineEmits(['refresh-datagrid']);

const handleSave = async () => {


  const loader = BlockContainer('ModalBodyLoadBalance');

  try {
    let response;

      response = await agencyStore.loadBalance(formData.value)

    ToastMessage('success', response.data.message, $translate('common.successfully'));
    emit('refresh-datagrid');

    closeModal();

  } catch (e) {
    errorService.apiClientError(e);
  } finally {
    UnBlocker(loader);
  }

}

const formData = ref({
  wallet_id: null,
  currency: null,
  amount: 0,
  description: null,
});

const modalWalletForm = ref()

onMounted(() => {
  modalWalletForm.value = new Modal(document.getElementById('ModalLoadBalance'), {
    backdrop: false,
    keyboard: false,
  });
});

const openModal = (wallet_info) => {
  formData.value.wallet_id = wallet_info.id;
  formData.value.currency = wallet_info.currency_code;


  formData.value.amount = 0;
  formData.value.description = null;


  modalWalletForm.value.show();
};

const closeModal = () => {
  modalWalletForm.value.hide();
};


defineExpose({
  openModal,
  closeModal
});
</script>

<style scoped>

</style>
