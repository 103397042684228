<template>
  <div id="dxGridBlock">
    <DxDataGrid
        ref="dxEditSelfMarkup"
        :show-borders="true"
        :data-source="agencyStore.selected_agency?.current_markups?.just_self_markups"
        :column-auto-width="true"
        :show-row-lines="true"
        theme="compact"
        :show-column-lines="true"
        :row-alternation-enabled="true"
        :remote-operations="false"
        :allow-column-resizing="true"
        :column-resizing-mode="'widget'"
        :width="'100%'"
        :height="500"
        @toolbarPreparing="(e) => dxOnToolbarPreparing(e, customToolBarOptions)"
        @contextMenuPreparing="dxOnContextMenuPreparing"
        @exporting="(e) => dxOnExcelExporting(e, 'Agency Self Markups', 'Markups')"
    >
      <DxExport :enabled="true" :allow-export-selected-data="false"/>
      <DxPaging :page-size="500"/>

      <DxColumn data-field="package_type.package.name" caption="Package Name"/>
      <DxColumn data-field="package_type.name" caption="Pakcage Type"/>
      <DxColumn data-field="category.name" caption="Markup Type"/>
      <DxColumn data-field="value" caption="Markup" cell-template="markupTemplate"/>
      <template #markupTemplate="{data:rowInfo}">
        <b v-if="rowInfo.data.category.name === 'percent'" class="text-primary">%{{ rowInfo.data.value }}</b>
        <b v-else class="text-dark">{{ priceFormatter(rowInfo.data.value, rowInfo.data.currency) }}</b>
      </template>

      <DxColumn type="buttons">
        <DxButton icon="edit" @click="(e) => modalEditAgencySelfMarkupRef.openModal(true, e.row.data)"/>
        <DxButton icon="trash" @click="(e) => deleteMarkup(e)"/>
      </DxColumn>

      <DxFilterRow :visible="true"/>
      <DxHeaderFilter :visible="true"/>
      <DxGroupPanel visible="true"/>
      <DxSearchPanel :visible="true" :highlight-case-sensitive="true"/>
    </DxDataGrid>
  </div>

  <ModalEditAgencySelfMarkup ref="modalEditAgencySelfMarkupRef" @refresh-agency-detail="emit('refresh-agency-detail')"/>

</template>

<script setup>
import {
  DxDataGrid,
  DxColumn,
  DxFilterRow,
  DxHeaderFilter,
  DxSearchPanel,
  DxGroupPanel,
  DxPaging,
  DxExport,
  DxButton
} from "devextreme-vue/data-grid";
import {
  dxOnExcelExporting,
  dxOnContextMenuPreparing,
  dxOnToolbarPreparing,
} from "@/core/services/devextreme.datagrid.service";
import {useAgencyStore} from "@/stores/agencyStore";
import {
  BlockContainer,
  ConfirmWithSwal,
  priceFormatter,
  ToastMessage,
  UnBlocker
} from "@/core/services/general.service";
import ModalEditAgencySelfMarkup from "@/components/Agency/EditSelfMarkup/ModalEditAgencySelfMarkup.vue";
import {ref} from "vue";
import {errorService} from "@/core/services/error.service";
import {agencyService} from "@/core/api/agency.api";
import {$translate} from "@/core/services/translator.service";

const modalEditAgencySelfMarkupRef = ref();
const dxEditSelfMarkup = ref();

const emit = defineEmits([
  'refresh-agency-detail'
])


const customToolBarOptions = [
  {
    location: "after",
    widget: "dxButton",
    options: {
      icon: "add",
      onClick: () => {
        modalEditAgencySelfMarkupRef.value.openModal();
      },
    },
  }
];

const deleteMarkup = async (e) => {

  let result = await ConfirmWithSwal(
      'warning',
      'Agency\'s markup will be deleted, do we continue?',
      'Please Confirm'
  )

  if (!result)
    return false;

  let markup_id = e.row.data.id;

  const loader = BlockContainer('dxGridBlock');

  try {
    const response = await agencyStore.deleteAgencyMarkup(markup_id);
    emit('refresh-agency-detail');

    ToastMessage(
        'success',
        response.data.message,
        $translate('common.successfully')
    );

  } catch (e) {
    errorService.apiClientError(e);
  } finally {
    UnBlocker(loader);
  }

};

const agencyStore = useAgencyStore();

</script>

<style lang="scss" scoped></style>
