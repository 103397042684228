<template>
  <div>
    <DxNumberBox
        :value="boxValue"
        :label-mode="labelMode"
        :label="label"
        :min="0"
        :format="{
                  type: 'currency',
                  precision: 2,
                  currency: currencyCode
                }"
        :input-attr="{ 'aria-label': 'Currency Format' }"
        @value-changed="onValueChanged"
    />
  </div>
</template>

<script setup>

import {DxNumberBox} from 'devextreme-vue/number-box';
import {useAuthStore} from "@/stores/authStore";
import {computed} from "vue";


const authStore = useAuthStore();

const props = defineProps({
  boxValue: {
    type: Number,
    default: 0,
  },
  label: {
    type: String,
    required: true,
  },
  labelMode: {
    type: String,
    default: 'floating'
  },
  currency: {
    type: String,
    default: null
  }
});

const emit = defineEmits(['update:boxValue', 'change']);

const currencyCode = computed(() => {
  let code = props.currency ?? authStore.currentCurrency?.code;

  switch (code) {
    case 'TL':
      return 'TRY';

    default:
      return code;
  }
})

const onValueChanged = (e) => {
  emit('update:boxValue', e.value);
  emit('change', e.value);
}

</script>

<style lang="scss" scoped>

</style>
