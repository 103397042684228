<template>
  <Breadcrumbs main="Varsayılan Yetki Tanımları"/>

  <div class="container-fluid">
    <div class="email-wrap bookmark-wrap">
      <div class="row">
        <div class="col-xl-12 col-md-12 box-col-12">
          <div class="email-right-aside bookmark-tabcontent contacts-tabs">
            <div class="card email-body radius-left" id="cardDrp">
              <div class="ps-0">
                <div class="tab-content">
                  <div class="tab-pane fade active show" id="pills-personal" role="tabpanel"
                       aria-labelledby="pills-personal-tab">
                    <div class="card mb-0">
                      <div class="card-header ">
                        <div class="row">
                          <div class="col-md-3">
                            <label for="model">Model:</label>
                            <select v-model="drpStore.model" @change="getModuleTreeForRole" class="form-control">
                              <option value="role">Yetki</option>
                              <option value="agency">Acente</option>
                            </select>
                          </div>
                          <div class="col-md-3">
                            <label for="role">Yetki:</label>
                            <select v-model="drpStore.selected_role" @change="getModuleTreeForRole"
                                    class="form-control" :disabled="isRoleDisabled">
                              <option value="">Seçiniz</option>
                              <option v-for="role in drpStore.roles" :key="role.id" :value="role.id">
                                {{ role.display_name }}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-3">
                            <label for="role">Modelleri Güncelle:</label>
                            <select v-model="drpStore.apply_selected_permission_to_all_users" class="form-control">
                              <option value="true">Evet</option>
                              <option value="false">Hayır</option>
                            </select>
                          </div>

                          <div class="col-sm-3">
                            <button @click="drpStore.updateState()" style="float: right;"
                                    class="btn btn-secondary float-right">Update State
                            </button>
                          </div>


                        </div>


                      </div>
                      <div class="card-body p-0 min-vh-100">
                        <div class="row">
                          <div class="col-md-4">
                            <ModuleAndModuleGroups/>
                          </div>
                          <div class="col-md-3">
                            <SubModules/>
                          </div>
                          <div class="col-md-5">
                            <Permissions/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useDefaultRolePermissionStore} from "@/stores/SystemConfiguration/defaultRolePermissionStore";
import {onMounted, ref, computed} from "vue";
import ModuleAndModuleGroups from "@/pages/configuration/default_role_permission/ModuleAndModuleGroups.vue";
import SubModules from "@/pages/configuration/default_role_permission/SubModules.vue";
import Permissions from "@/pages/configuration/default_role_permission/Permissions.vue";


const drpStore = useDefaultRolePermissionStore();

onMounted(async () => {
  drpStore.selected_role = "";
  drpStore.module_tree = [];
  drpStore.sub_module_tree = [];
  drpStore.permission_tree = [];
  await drpStore.getRoles();
});

const getModuleTreeForRole = async () => {
  drpStore.module_tree = [];
  drpStore.sub_module_tree = [];
  drpStore.permission_tree = [];
  await drpStore.getModuleTreeFroRole();
}

const isRoleDisabled = computed(() => {
  if (drpStore.model === 'agency') {
    drpStore.selected_role = "";
    return true;
  }
  return false;
});

</script>



