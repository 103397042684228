<template>
  <div>
    <select
        ref="select2"
        :name="name"
        :class="[{ 'is-invalid': meta.error }, 'form-control']"
        :id="id"
    >
      <option value="">{{ placeholder }}</option>
      <option v-for="option in options" :key="option[customValueKey]" :value="option[customValueKey]">
        {{ option[textKey] }}
      </option>
    </select>
    <ErrorMessage :name="name" class="invalid-feedback"/>
  </div>
</template>

<script setup>
import {ref, watch, onMounted, onBeforeUnmount} from 'vue';
import {useField, ErrorMessage} from 'vee-validate';
import $ from 'jquery';
import 'select2/dist/css/select2.css';

const props = defineProps({
  dropDownParent: {
    type: String,
    default: false,
  },
  name: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    default: '',
  },
  customValueKey: {
    type: String,
    default: 'id',
  },
  textKey: {
    type: String,
    default: 'name'
  },
  options: {
    type: Array,
    required: true,
  },
  placeholder: {
    type: String,
    default: 'Select an option',
  },
  rules: {
    type: String,
    default: 'required',
  },
  modelValue: {
    type: [String, Number],
    default: '',
  },
});

const emit = defineEmits(['update:modelValue']);

const {value: fieldValue, meta, setTouched} = useField(props.name, props.rules);
const select2 = ref(null);

const initializeSelect2 = () => {
  const select2Options = {
    placeholder: props.placeholder,
    allowClear: true,
  };

  if (props.dropDownParent) {
    select2Options.dropdownParent = $(props.dropDownParent);
  }

  $(select2.value).select2(select2Options);

  $(select2.value).on('change', (e) => {
    const value = e.target.value;
    fieldValue.value = value;
    emit('update:modelValue', value);
  });

  $(select2.value).on('select2:close', () => {
    setTouched();
  });

  // Initial value setup
  if (props.modelValue) {
    $(select2.value).val(props.modelValue).trigger('change');
  }
};

onMounted(() => {
  initializeSelect2();
});

onBeforeUnmount(() => {
  $(select2.value).off('change select2:close');
  $(select2.value).select2('destroy');
});

watch(() => props.modelValue, (newValue) => {
  if ($(select2.value).val() !== newValue) {
    $(select2.value).val(newValue).trigger('change');
  }
});
</script>

<style scoped>
.invalid-feedback {
  display: block;
}
</style>
