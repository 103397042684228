import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";
import {useAuthStore} from "@/stores/authStore";
import {ToastMessage} from "@/core/services/general.service";

export const dxOnExcelExporting = (e, fileName, sheetName) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(sheetName);

    exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: false,
    }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], {type: "application/octet-stream"}), `${fileName}.xlsx`);
        });
    });

    e.cancel = true;
};

export const dxOnContextMenuPreparing = (e, additionalItems = []) => {
    e.items = [
        {
            text: "Yenile",
            icon: "refresh",
            onItemClick: () => {
                e.component.refresh();
            },
        },
        {
            text: "Görünümü Sıfırla",
            icon: "revert",
            onItemClick: () => {
                e.component.state(null);
                localStorage.removeItem("dataGridState");
                e.component.option("stateStoring.enabled", true);
            },
        },
        ...additionalItems,
    ];
};

export const dxPageSizes = () => {
    return [25, 50, 100, 250, 500, "all"];
};

export const dxOnToolbarPreparing = (e, customOption = []) => {
    e.toolbarOptions.items.unshift(
        {
            location: "after",
            widget: "dxButton",
            options: {
                icon: "refresh",
                onClick: () => {
                    e.component.refresh();
                },
            },
        },
        ...customOption,
    );
};


export const dxDateEditorOptions = () => {
    return {
        displayFormat: "MM/dd/yyyy",
        type: "date",
    };
};

export const dxOnBeforeSendFunction = (method, ajaxOptions) => {
    const authStore = useAuthStore();

    ajaxOptions.xhrFields = {withCredentials: true};
    ajaxOptions.headers = {
        Authorization: `Bearer ${authStore.token}`,
        Currency: localStorage.getItem('currency'),
    };
};

export const dxOnAjaxErrorHandler = (error) => {
    // console.log(error);
    if (error.xhr && error.xhr.responseText) {
        try {
            const response = JSON.parse(error.xhr.responseText);
            if (error.xhr.status === 422 && response.errors) {
                Object.keys(response.errors).forEach((key) => {
                    ToastMessage("error", response.errors[key].join(", "), "Hata!");
                });
            } else {
                console.error("Hata mesajı:", response.message || "Bilinmeyen hata");
            }
        } catch (parseError) {
            console.error("Yanıtı çözümlerken hata oluştu:", parseError.message);
        }
    } else {
        console.error("Hata:", error.error || "Bilinmeyen hata");
    }
};

