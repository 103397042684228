<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <table
            class="table table-bordered table-striped mb-4 table-padding-5-20-5-20"
        >
          <thead>
          <tr>
            <th colspan="2" class="text-center fw-bolder">
              {{ $translate('agencies.agency_detail.tabs_content.general.general1') }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="info">
              {{ $translate('agencies.agency_detail.tabs_content.general.id') }}
            </td>
            <td>{{ the_agency.id }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('agencies.agency_detail.tabs_content.general.title') }}
            </td>
            <td>{{ the_agency.title }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('agencies.agency_detail.tabs_content.general.slug') }}
            </td>
            <td>{{ the_agency.slug }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('agencies.agency_detail.tabs_content.general.email') }}
            </td>
            <td>{{ the_agency.email }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('agencies.agency_detail.tabs_content.general.country') }}
            </td>
            <td>{{ the_agency.country?.name }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('agencies.agency_detail.tabs_content.general.state') }}
            </td>
            <td>{{ the_agency.state?.name }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('agencies.agency_detail.tabs_content.general.city') }}
            </td>
            <td>{{ the_agency.city?.name }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('agencies.agency_detail.tabs_content.general.address') }}
            </td>
            <td>{{ the_agency.customized?.address }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('agencies.agency_detail.tabs_content.general.post_code') }}
            </td>
            <td>{{ the_agency.customized?.post_code }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('agencies.agency_detail.tabs_content.general.timezone') }}
            </td>
            <td>{{ the_agency?.timezone }}</td>
          </tr>

          </tbody>
        </table>
      </div>
      <div class="col-md-6">
        <table
            class="table table-bordered table-striped mb-4 table-padding-5-20-5-20"
        >
          <thead>
          <tr>
            <th colspan="2" class="text-center fw-bolder">
              {{ $translate('agencies.agency_detail.tabs_content.general.general2') }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="info">
              {{ $translate('agencies.agency_detail.tabs_content.general.status') }}
            </td>

            <td>
              <StatusBadge :status="the_agency.status"/>
            </td>
          </tr>
          <tr>
            <td>
              {{ $translate('agencies.agency_detail.tabs_content.general.status_description') }}
            </td>
            <td>{{ the_agency.status_description }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('agencies.agency_detail.tabs_content.general.created_at') }}
            </td>
            <td>{{ globalMoment(the_agency.created_at, 'DD.MM.YYYY HH:mm') }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('agencies.agency_detail.tabs_content.general.created_by') }}
            </td>
            <td>{{ the_agency.user?.name_surname }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('agencies.agency_detail.tabs_content.general.web_site') }}
            </td>
            <td>{{ the_agency.web_site }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('agencies.agency_detail.tabs_content.general.user_count') }}
            </td>
            <td>{{ the_agency.agency_users ? the_agency.agency_users.length : 0 }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('agencies.agency_detail.tabs_content.general.sub_agency_count') }}
            </td>
            <td>{{ the_agency.sub_agencies?.length }}</td>
          </tr>
          <tr>
            <td>
              {{ $translate('agencies.agency_detail.tabs_content.general.allowed_languages') }}
            </td>
            <td>
              <b v-for="(lang, index) in allowed_languages" :class="{'text-primary': index===0}" class="me-2" :key="index"
              >{{ lang.LangName }},</b>
            </td>
          </tr>
          <tr>
            <td>
              {{ $translate('agencies.agency_detail.tabs_content.general.allowed_currencies') }}
            </td>
            <td>
              <b v-for="(currency, index) in the_agency?.customized?.currencies" :class="{'text-primary': index===0}" class="me-2" :key="index"
              >{{ currency }},</b>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useAgencyStore} from '@/stores/agencyStore';
import {computed} from 'vue';
import {$translate} from '@/core/services/translator.service';
import StatusBadge from "@/components/StatusBadge.vue";
import {globalMoment} from "../../../../core/services/general.service";

const agencyStore = useAgencyStore();
const the_agency = computed(() => agencyStore.selected_agency);

const allowed_languages = computed(() => the_agency.value.allowed_languages);

</script>

<style scoped></style>
