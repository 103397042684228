import LaravelEncrypt from "@/core/services/laravel.encrypt.service";
import i18n from "@/core/locales/locales.config";

export const $translate = (translationKey, object = false) => {
  const laravelEncrypt = new LaravelEncrypt();

  const parts = translationKey.split(".");
  //console.log("parts => ", parts);

  const encryptedParts = parts.map((part) => laravelEncrypt.encrypt(part));
  //console.log("encryptedParts => ", encryptedParts);

  let encryptedTranslationKey = encryptedParts.join(".");
  //console.log("encryptedTranslationKey => ", encryptedTranslationKey);

  let cryptedTranslation = i18n.global.t(encryptedTranslationKey);
  //console.log("cryptedTranslation => ", cryptedTranslation);
  //console.log("i18n selected lang", i18n.global.locale)

  let decryptedTranslation = laravelEncrypt.decrypt(cryptedTranslation);
  //console.log("decryptedTranslation => ", decryptedTranslation);

  if (object) {
    return updateTranslation(decryptedTranslation, object);
  } else {
    return decryptedTranslation;
  }
};

const updateTranslation = (decryptedTranslation, object) => {
  let updatedTranslation = decryptedTranslation;

  // Gelen objenin anahtar-değer çiftlerini işleyelim
  for (const [key, value] of Object.entries(object)) {
    // Anahtarları süslü parantez içinde değiştir
    const placeholder = `{${key}}`;
    updatedTranslation = updatedTranslation.replace(new RegExp(placeholder, "g"), value);
  }

  return updatedTranslation;
};

