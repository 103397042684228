<template>
  <DxDataGrid
      :show-borders="true"
      :data-source="dataSource"
      :show-row-lines="true"
      :show-column-lines="true"
      :row-alternation-enabled="true"
      ref="dataGridRef"
      :remote-operations="{
      groupPaging: true,
      paging: true,
      filtering: true,
      sorting: true,
      grouping: true,
      summary: true,
    }"
      :allow-column-resizing="true"
      :column-resizing-mode="'widget'"
      :width="'100%'"
      :column-auto-width="true"
      :height="700"
      @toolbarPreparing="dxOnToolbarPreparing"
      @contextMenuPreparing="dxOnContextMenuPreparing"
      @exporting="(e) => dxOnExcelExporting(e, 'Modüller', 'Modüller')"
  >
    <DxColumnChooser :enabled="true"/>
    <DxExport :enabled="true" :allow-export-selected-data="false"/>
    <DxPaging :page-size="500"/>
    <DxPager
        :visible="true"
        :allowed-page-sizes="dxPageSizes"
        :display-mode="'compact'"
        :show-page-size-selector="true"
        :show-info="true"
        :show-navigation-buttons="true"
    />

    <DxColumn data-field="module_group_name" caption="Modül Grubu">
      <DxRequiredRule/>
    </DxColumn>
    <DxColumn data-field="name" caption="Modül Adı">
      <DxRequiredRule/>
    </DxColumn>

    <DxColumn data-field="ico" caption="Icon" cell-template="iconPreviewTemplate"></DxColumn>

    <template #iconPreviewTemplate="{ data: rowInfo }">
      <div class="flex justify-center">
        <i class="sidebar-module-icon" :class="rowInfo.data.ico"></i> {{ rowInfo.data.ico }}
      </div>
    </template>

    <DxColumn data-field="slug" :allow-editing="false" caption="Slug"></DxColumn>
    <DxColumn data-field="no_sub_module"
              caption="Alt Modül"
              :allow-editing="false"
              cell-template="noSubModuleTemplate"
    >
      <DxLookup :data-source="moduleStore.noSubModuleTypes" value-expr="value" display-expr="display_name"/>
    </DxColumn>
    <template #noSubModuleTemplate="{ data: rowInfo }">
      <div class="flex justify-center">
        <b :class="rowInfo.data.no_sub_module ? 'text-success' : 'text-danger'"
        >{{ rowInfo.data.no_sub_module ? 'Evet' : 'Hayır' }}</b>
      </div>
    </template>
    <DxColumn data-field="description" caption="Açıklama">
      <DxRequiredRule/>
    </DxColumn>
    <DxColumn data-field="location" caption="Lokasyon">
      <DxLookup :data-source="moduleStore.moduleLocations" value-expr="name" display-expr="display_name"/>
    </DxColumn>
    <DxColumn
        data-field="created_at_format"
        data-type="date"
        caption="Kayıt Tarihi"
        :allow-editing="false"
        sort-order="desc"
        :editor-options="dxDateEditorOptions"
    >
    </DxColumn>


    <DxFilterRow :visible="true"/>
    <DxHeaderFilter :visible="true"/>

    <DxEditing
        :allow-adding="false"
        :allow-updating="false"
        :allow-deleting="true"
        mode="row"
    />
    <DxColumn type="buttons">
      <DxButton icon="edit" @click="(e) => editModule(e)"/>
      <DxButton name="delete"/>
    </DxColumn>

    <DxSearchPanel
        :visible="true"
        :highlight-case-sensitive="true"
    />

    <DxToolbar>
      <DxItem location="after">
        <button @click="createModule()" class="btn btn-primary btn-xs">
          <i class="fa fa-plus"></i>
        </button>
      </DxItem>
      <DxItem name="columnChooserButton"/>
      <DxItem name="exportButton"/>
    </DxToolbar>
  </DxDataGrid>


  <ModalModuleForm
      @close-modal="closeModal()"
      @refresh-datagrid="dataGridRef.instance.refresh()"
      :title="formTitle"
  />
</template>

<script setup>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxSearchPanel,
  DxRequiredRule,
  DxLookup,
  DxPaging,
  DxPager,
  DxExport,
  DxToolbar,
  DxItem,
  DxColumnChooser,
  DxButton,
} from 'devextreme-vue/data-grid';
import {createStore} from 'devextreme-aspnet-data-nojquery';
import {systemConfigurationService} from "@/core/api/system-configuration.api";
import {
  dxOnExcelExporting,
  dxOnContextMenuPreparing,
  dxPageSizes,
  dxOnToolbarPreparing,
  dxDateEditorOptions,
  dxOnBeforeSendFunction, dxOnAjaxErrorHandler
} from "@/core/services/devextreme.datagrid.service";
import {onMounted, reactive, ref} from "vue";
import 'bootstrap/dist/js/bootstrap';
import ModalModuleForm from "@/pages/configuration/modules/includes/modals/ModalModuleForm.vue";
import {Modal} from 'bootstrap';
import {useModuleStore} from "@/stores/SystemConfiguration/moduleStore";
import {useSubModuleStore} from "@/stores/SystemConfiguration/subModuleStore";

const moduleStore = useModuleStore();
const noSubModuleTypes = moduleStore.noSubModuleTypes;

const subModuleStore = useSubModuleStore();


const dataGridRef = ref(DxDataGrid);

const url = systemConfigurationService.moduleResource();

const dataSource = createStore({
  key: 'id',
  loadUrl: `${url}`,
  insertUrl: `${url}`,
  updateUrl: `${url}/update`,
  deleteUrl: `${url}/delete`,
  onBeforeSend: dxOnBeforeSendFunction,
  onAjaxError: dxOnAjaxErrorHandler,
  onRemoved: (key) => {
    updateModulesOnTheStore();
  },
});


const updateModulesOnTheStore = () => {
  subModuleStore.getModules();
}

const modalModuleForm = ref(null);

onMounted(() => {
  modalModuleForm.value = new Modal(document.getElementById('ModalModuleForm'), {
    backdrop: 'static',
    keyboard: false,
    focus: true,
    show: false
  });
});

const editMode = ref(false);
const formTitle = ref('Modül Oluştur');
const moduleDetails = ref(null);

const createModule = () => {
  moduleDetails.value = null;
  moduleStore.editMode = false;
  moduleStore.editModuleDetails = null;
  formTitle.value = 'Modül Oluştur';
  openModal();
}

const editModule = (e) => {
  // console.log("here is the e.row.data here", e.row.data);
  moduleStore.editModuleDetails = e.row.data;
  moduleStore.editMode = true;
  formTitle.value = 'Modül Düzenle';
  openModal();
}

const openModal = () => {
  modalModuleForm.value.show();
};

const closeModal = () => {
  modalModuleForm.value.hide();
  updateModulesOnTheStore();
};
</script>