import {defineStore} from "pinia";
import {systemConfigurationService} from "@/core/api/system-configuration.api";
import {BlockContainer, ToastMessage, UnBlocker} from "@/core/services/general.service";
import {errorService} from "@/core/services/error.service";
import {$translate} from "@/core/services/translator.service";

export const useDefaultRolePermissionStore = defineStore('defaultRolePermissionStore', {
    state: () => ({
        model: 'role',
        selected_role: "",
        apply_selected_permission_to_all_users: "true",
        roles: [],
        module_tree: [],
        sub_module_tree: [],
        permission_tree: [],
        selected_module: null,
        selected_sub_module: null,
        permission_details: null,
        the_models_who_may_be_added_to_permission: null,
    }),
    getters: {},
    actions: {

        async getRoles() {
            const loader = BlockContainer('cardDrp');
            try {
                const response = await systemConfigurationService.getRoles();
                this.roles = response.data.roles;
            } catch (e) {
                errorService.apiClientError(e);
            } finally {
                UnBlocker(loader);
            }
        },

        async getModuleTreeFroRole() {

            let role_id = this.selected_role;
            let model = this.model;

            if (role_id === '' && model === 'role') {
                this.module_tree = [];
                return false;
            }


            const loader = BlockContainer('cardDrp');

            try {
                const response = await systemConfigurationService.getModuleTreeFroRole(role_id, model);
                this.module_tree = response.data.module_tree;
            } catch (e) {
                console.error("Error fetching getModuleTreeFroRole data.", e);
            } finally {
                UnBlocker(loader);
            }
        },

        async getSubModuleTree(module_id) {
            const loader = BlockContainer('cardDrp');
            try {
                const response = await systemConfigurationService.getSubModuleTree(module_id, this.selected_role, this.model);
                this.sub_module_tree = response.data.sub_modules;
            } catch (e) {
                errorService.apiClientError(e);
            } finally {
                UnBlocker(loader);
            }
        },

        async getPermissionTree(module_id) {
            const loader = BlockContainer('cardDrp');
            try {
                const response = await systemConfigurationService.getPermissionTree(module_id, this.selected_role, this.model);
                this.permission_tree = response.data.permissions;
            } catch (e) {
                errorService.apiClientError(e);
            } finally {
                UnBlocker(loader);
            }
        },

        async savePermissions(permissions) {
            const loader = BlockContainer('cardDrp');
            try {
                let data = {
                    permissions: permissions,
                    role_id: this.selected_role,
                    model: this.model,
                    apply_selected_permission_to_all_users: this.apply_selected_permission_to_all_users
                };
                return await systemConfigurationService.savePermissions(data);
            } catch (e) {
                errorService.apiClientError(e);
            } finally {
                UnBlocker(loader);
            }
        },

        async updateState() {
            await this.getModuleTreeFroRole(this.selected_role);
            await this.getSubModuleTree(this.selected_module);
            await this.getPermissionTree(this.selected_sub_module);
        },


        async getPermissionDetails(permission_id) {
            try {
                const response = await systemConfigurationService.getPermissionDetails(permission_id);
                this.permission_details = response.data.permission;
            } catch (e) {
                errorService.apiClientError(e);
            }
        },

        async deletePermission(permission_id, model_id, model_type) {
            try {
                const response = await systemConfigurationService.deletePermission(permission_id, model_id, model_type);
                ToastMessage('success', response.data.message, $translate('common.successfully'));

            } catch (e) {
                errorService.apiClientError(e);
            }
        },

        async getTheModelsWhoMayBeAddedToPermission(permission_id, model) {
            try {
                const response = await systemConfigurationService.getTheModelsWhoMayBeAddedToPermission(permission_id, model);
                this.the_models_who_may_be_added_to_permission = response.data.models;
            } catch (e) {
                errorService.apiClientError(e);
            }
        },

        async saveModelsPermission(permission_id, models, model_type) {
            try {
                const response = await systemConfigurationService.saveModelsPermission(permission_id, models, model_type);
                ToastMessage('success', response.data.message, $translate('common.successfully'));
            } catch (e) {
                errorService.apiClientError(e);
            }
        },
    }
});