<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <img style="width: 35px;" class="b-r-10" :src="authStore.user?.avatar" alt=""/>
      <div class="media-body">
        <span>{{ authStore.user?.name }}</span>
        <p class="mb-0 font-roboto">
          {{ $translate('system.roles.' + authStore.user?.role) }} <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <div v-if="false">
        <li>
          <vue-feather type="user"></vue-feather>
          <span>Account </span>
        </li>
        <li>
          <vue-feather type="mail"></vue-feather>
          <span>Inbox</span>
        </li>
        <li>
          <vue-feather type="file-text"></vue-feather>
          <span>Taskboard</span>
        </li>
        <li>
          <vue-feather type="settings"></vue-feather>
          <span>Settings</span>
        </li>
      </div>
      <li @click="handleLogout()">
        <vue-feather type="log-in"></vue-feather>
        <span>Log out</span>
      </li>
    </ul>
  </li>
</template>

<script setup>
import {useAuthStore} from "@/stores/authStore";
import {authService} from "@/core/api/auth/auth.api";
import {ToastMessage} from "@/core/services/general.service";
import {$translate} from "../../core/services/translator.service";

const authStore = useAuthStore();

const handleLogout = async () => {
  try {
    await authStore.logout();
  } catch (error) {
    console.log("error at logout", error);
  }
}


</script>
