import {defineStore} from "pinia";
import {locationService} from "@/core/api/location.api";

export const useLocationStore = defineStore('locationStore', {
    state: () => ({}),
    getters: {},
    actions: {

        async getCountries() {
            try {
                return await locationService.getCountries();
            } catch (error) {
                console.error("error at getCountries from locationStore", error);
            }
        },


        async getStates(country_id) {
            try {
                return await locationService.getStates(country_id);
            } catch (error) {
                console.error("error at getStates from locationStore", error);
            }
        },

        async getCities(state_id) {
            try {
                return await locationService.getCities(state_id);
            } catch (error) {
                console.error("error at getCities from locationStore", error);
            }
        },

    }
});
